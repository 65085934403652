import _ from 'lodash';
import { Link } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { App, Card } from 'antd';
import Meta from 'antd/es/card/Meta';

import WarehouseSiren from 'pages/boxes/components/WarehouseSiren';
import { Warehouse } from 'features/interfaces/Warehouse';
import { useIsControllerAvailableMutation } from 'features/warehouses/warehousesApiSlice';
import { CustomAny, CustomVoid } from 'shared/types/generics';

import './Warehouse.scss';

interface WarehouseCardValues {
  warehouse: Warehouse;
  isCurrentUserAdmin?: boolean;
}

const WarehouseCard: FC<WarehouseCardValues> = (props) => {
  const { warehouse, isCurrentUserAdmin } = props;

  const { warehouseId, name: warehouseName } = warehouse;

  const [isActiveController, setActiveController] = useState<boolean | null>(null);
  const { notification } = App.useApp();

  const [isControllerAvailable] = useIsControllerAvailableMutation();

  const checkControllerStatus = useCallback(async (): Promise<CustomVoid> => {
    try {
      const result = await isControllerAvailable(warehouseId);

      if ('error' in result) {
        setActiveController(false);
        notification.error({
          message: `Внимание, склад ${warehouseName} офлайн`,
          placement: 'top',
        });
      } else {
        if (result.data.isOk) {
          setActiveController(true);
        }
      }
    } catch (error: CustomAny) {
      console.log('error', error);
    }
  }, []);

  const setIntervalImmediately = (foo: CustomAny): NodeJS.Timer => {
    foo();
    const checker = setInterval(checkControllerStatus, 15000);

    return checker;
  };

  useEffect(() => {
    const checker = setIntervalImmediately(checkControllerStatus);

    return () => {
      clearInterval(checker);
    };
  }, [checkControllerStatus]);

  const getWarehouseActions = useCallback(() => {
    const actions = [
      <Link to="/warehouses/settings" state={{ warehouseId }} key="setting" className="warehouse-icon-active">
        Настройки
      </Link>,
      <Link
        to={`/warehouses/${String(warehouseId)}/history`}
        state={{ warehouseId, name: warehouse.name }}
        key="history"
        className="warehouse-icon-active"
      >
        История
      </Link>,
      <Link to={`/boxes?warehouseId=${String(warehouseId)}`} key="boxes" state={{ warehouseId }} className="warehouse-icon-active">
        Боксы
      </Link>,
    ];

    if (!isCurrentUserAdmin) {
      actions.shift();
    }

    return actions;
  }, [isCurrentUserAdmin, warehouseId]);

  return (
    <Card hoverable actions={getWarehouseActions()}>
      <Meta
        title={
          <div className="flex justify-between items-center">
            {warehouse.name}
            <div>
              {!_.isNull(isActiveController) && (
                <span className={`${isActiveController ? 'bg-green-500' : 'bg-rose-500'} rounded p-1 mx-3`}>
                  {isActiveController ? 'онлайн' : 'офлайн'}
                </span>
              )}
              {warehouse.alertLockNumber && <WarehouseSiren warehouseId={warehouseId} />}
            </div>
          </div>
        }
        description={warehouse.address}
      />
    </Card>
  );
};

export default WarehouseCard;
