import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'features/interfaces/UserState';
import { warehouseUtil } from 'features/warehouses/warehousesApiSlice';
import { clearAuthToken, getAuthToken, getEmailFromToken, getRefreshToken, getUsernameFromToken } from 'shared/helpers/accessTokenHelper';
import { Nullable } from 'shared/types/generics';
import { RootState } from 'store/store';

const initialState: UserState = {
  username: getUsernameFromToken(),
  email: getEmailFromToken(),
  token: getAuthToken(),
  refresh: getRefreshToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<UserState>) => {
      const { username, token, refresh, email } = action.payload;
      state.username = username;
      state.token = token;
      state.refresh = refresh;
      state.email = email;
    },
    logout: (state) => {
      warehouseUtil.invalidateTags(['Warehouses']);
      state.username = null;
      state.token = null;
      state.email = null;
      state.refresh = null;
      clearAuthToken();
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): Nullable<string> => state.auth.username;
export const selectCurrentToken = (state: RootState): Nullable<string> => state.auth.token;
export const selectCurrentRefresh = (state: RootState): Nullable<string> => state.auth.refresh;
