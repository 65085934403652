/* eslint-disable react/no-children-prop */
import { FC, useCallback, useMemo, useState } from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import { Badge, Button, App, Spin } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import { JWTUser, useGetMeQuery } from 'features/auth/authApiSlice';
import { useGetAllBoxesQuery, useOpenBoxMutation, useVerifyStateMutation } from 'features/boxes/boxesApiSlice';
import { LockState } from 'features/enums/lockState.enum';
import { BoxQuery } from 'features/interfaces/Box';
import { useGetWarehouseByWarehouseIdQuery } from 'features/warehouses/warehousesApiSlice';
import NotFoundPage from 'pages/notFound/NotFoundPage';
import BackButton from 'shared/components/backButton/BackButton';
import Header from 'shared/components/header/Header';
import CustomTable from 'shared/components/table/Table';
import Timer from 'shared/components/timer/Timer';
import { CustomAny, CustomVoid } from 'shared/types/generics';
import { castFromMsToMin, isManager } from 'shared/utils/functions';
import { useLocationQuery } from 'shared/utils/hooks';
import { getQueryErrorMessage } from 'shared/helpers/queryErrorMessageHelper';

import EditableCollapse from './components/EditableCollapse';
import { highlightRowBoxesList } from './utils';
import WarehouseSiren from './components/WarehouseSiren';
import WarehouseLocks from './components/WarehouseLocks';

import './BoxesList.scss';

const BoxesList: FC = () => {
  const locationQuery = useLocationQuery();
  const { notification, modal } = App.useApp();

  if (!locationQuery.get('warehouseId')) {
    return <NotFoundPage />;
  }

  const [query] = useState({ warehouseId: locationQuery.get('warehouseId') });

  const { data: userData } = useGetMeQuery();
  const { data: boxes } = useGetAllBoxesQuery(query as BoxQuery);
  const { data: warehouse } = useGetWarehouseByWarehouseIdQuery(locationQuery.get('warehouseId') as string);

  const [verifyState] = useVerifyStateMutation();
  const [openBox] = useOpenBoxMutation();

  const handleVerifyState = useCallback(
    async (boxId: string): Promise<CustomVoid> => {
      await verifyState({ boxId, isStateVerified: true });
    },
    [verifyState],
  );

  const handleOpenBox = useCallback(
    async (boxId: string, boxNumber: string): Promise<CustomVoid> => {
      modal.confirm({
        title: `Открытие бокса ${boxNumber}`,
        content: 'Вы уверены, что хотите открыть бокс?',
        onOk: async () => {
          const result = await openBox({ boxId, userId: (userData as JWTUser).userId });
          if ('error' in result) {
            notification.error({
              message: 'Ошибка',
              description: getQueryErrorMessage(result.error),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: 'Успешно',
              placement: 'bottomRight',
            });
          }
        },
      });
    },
    [openBox, userData, modal, notification],
  );

  const isCurrentUserManager = userData && isManager(userData.role);

  const columns = useMemo(
    () => [
      {
        title: 'Номер бокса',
        key: 'boxNumber',
        dataIndex: 'boxNumber',
        align: 'center',
      },
      {
        title: 'Номер замка',
        key: 'lockNumber',
        dataIndex: 'lockNumber',
        width: '15%',
        align: 'center',
        render: (lockNumber: string, box: CustomAny) => (
          <EditableCollapse value={lockNumber} boxId={box.boxId} mask="9:99:99" fieldToUpdate="lockNumber" />
        ),
        hidden: isCurrentUserManager,
      },
      {
        title: 'Имя арендатора',
        key: 'customerName',
        dataIndex: 'customerName',
        align: 'center',
      },
      {
        title: 'Доступ к боксу',
        key: 'accessDenied',
        dataIndex: 'accessDenied',
        render: (accessDenied: string) => <span>{accessDenied ? 'Нет' : 'Есть'}</span>,
      },
      {
        title: 'Сработка сигнала "долгое открытие"',
        key: 'openedTimeToAlert',
        dataIndex: 'openedTimeToAlert',
        width: '10%',
        align: 'center',
        render: (value: number, box: CustomAny) => (
          <EditableCollapse
            value={`${castFromMsToMin(value)} мин`}
            boxId={box.boxId}
            mask="999"
            fieldToUpdate="openedTimeToAlert"
            placeholder="Введите значение в мин"
            isNeedCast={true}
          />
        ),
        hidden: isCurrentUserManager,
      },
      {
        title: 'Состояние бокса',
        key: 'isStateVerified',
        dataIndex: 'isStateVerified',
        align: 'center',
        render: (isOk: boolean, record: CustomAny) => {
          return (
            <div>
              {isOk ? 'Подтверждено' : 'Требует проверки'}
              {!isOk && (
                <Button
                  onClick={async () => {
                    await handleVerifyState(record.boxId);
                  }}
                  className="ml-1"
                >
                  Подтвердить
                </Button>
              )}
            </div>
          );
        },
      },
      {
        title: 'Последнее открытие бокса',
        key: 'lastOpeningTime',
        align: 'center',
        dataIndex: 'lastOpeningTime',
        render: (date: string) => (date ? new Date(date).toLocaleString('ru') : 'нет информации'),
      },
      {
        title: 'Состояние замка',
        key: 'lockStatus',
        align: 'center',
        dataIndex: 'lockStatus',
        render: (status: string, record: CustomAny) => (
          <div>
            <Badge
              text={`${status === LockState.OPENED ? 'открыт' : status === LockState.CLOSED ? 'закрыт' : 'нет информации'}`}
              color={status === LockState.OPENED ? 'green' : status === LockState.CLOSED ? 'red' : 'yellow'}
            />

            {status === LockState.CLOSED && (
              <Button
                type="ghost"
                className="box-lock-custom-icon"
                shape="circle"
                onClick={async () => {
                  await handleOpenBox(record.boxId, record.boxNumber);
                }}
                icon={<UnlockOutlined style={{ fontSize: 15, fontWeight: 'bold', cursor: 'pointer' }} />}
              />
            )}

            {status === LockState.OPENED && record.lastOpeningTime && (
              <Timer beginning={dayjs().diff(dayjs(record.lastOpeningTime), 'm')} unit="мин" />
            )}
          </div>
        ),
        filters: [
          {
            text: 'Открыт',
            value: 'opened',
          },
          {
            text: 'Закрыт',
            value: 'closed',
          },
        ],
        onFilter: (value: string, record: CustomAny) => record.lockStatus === value,
      },
    ],
    [isCurrentUserManager, handleOpenBox, handleVerifyState],
  );

  return (
    <div className="flex flex-col">
      <Header
        title={<BackButton path="/warehouses" />}
        children={
          <>
            {!_.isEmpty(warehouse) && warehouse?.internalEquipment && (
              <div className="flex justify-end">
                <WarehouseLocks
                  warehouseId={warehouse.warehouseId}
                  locks={warehouse.internalEquipment}
                  className="p-3 flex flex-row justify-end"
                />
              </div>
            )}
            {!_.isEmpty(warehouse) && warehouse?.alertLockNumber && <WarehouseSiren warehouseId={warehouse.warehouseId} />}
          </>
        }
        border={false}
      />
      <CustomTable
        data={boxes}
        columns={columns.filter((column) => !column.hidden) as CustomAny}
        rowKey={'boxId'}
        rowClassName={highlightRowBoxesList}
        bordered={false}
        search={true}
        searchField="boxNumber"
        searchPlaceholder="Введите номер бокса"
        pagination={{ defaultPageSize: 50 }}
        size="small"
      />
    </div>
  );
};

export default BoxesList;
