import { apiSlice } from 'api/apiSlice';
import { Paginated } from 'features/interfaces/common';
import { User } from 'features/interfaces/User';
import { UserDocument } from 'features/interfaces/UserDocument';

import { CREATE_USER_PATH, USERS_BASE_PATH } from './constants';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<Paginated<UserDocument>, string>({
      query: () => USERS_BASE_PATH,
      keepUnusedDataFor: 10,
      providesTags: (result) => ['Users'],
    }),
    addUser: builder.mutation<User, User>({
      query: (user: User) => ({
        url: CREATE_USER_PATH,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<UserDocument, UserDocument>({
      query: (user: UserDocument) => ({
        url: `${USERS_BASE_PATH}/${String(user.userId)}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation<User, string>({
      query: (userId: string) => ({
        url: `${USERS_BASE_PATH}/${String(userId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useGetAllUsersQuery, useAddUserMutation, useUpdateUserMutation, useDeleteUserMutation } = usersApiSlice;
