import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import { useGetMeQuery } from 'features/auth/authApiSlice';

const Authorization = (allowedRole: string) => (WrappedComponent: JSX.Element) => {
  const { data } = useGetMeQuery();
  const navigate = useNavigate();

  if (data?.role.includes(allowedRole)) {
    return WrappedComponent;
  }

  return (
    <div className="p-[3%]">
      <Result
        status="403"
        title="Доступ запрещен!"
        subTitle="Обратитесь к администратору"
        extra={
          <Button
            type="primary"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            Вернуться назад
          </Button>
        }
      />
    </div>
  ) as JSX.Element;
};

export default Authorization;
