import { LockState } from 'features/enums/lockState.enum';
import { BoxWithHistory } from 'features/interfaces/Box';
import { WarehouseSummary } from 'features/interfaces/Warehouse';
import _ from 'lodash';
import { CustomAny, CustomVoid } from 'shared/types/generics';
import { addMs, isDateAfterNow } from 'shared/utils/functions';

export const generateAlertCounter = (numberOfAlerts: number): string => {
  switch (numberOfAlerts) {
    case 1:
      return `${numberOfAlerts} активная тревога`;
    case 2:
    case 3:
    case 4:
      return `${numberOfAlerts} активные тревоги`;
    default:
      return `${numberOfAlerts} активных тревог`;
  }
};

export const countBoxesWithAlert = (boxes: BoxWithHistory[]): number => boxes.filter((box) => !box.isStateVerified).length;

export const highlightRowSummary = (record: CustomAny): string =>
  record.isStateVerified
    ? record.lockStatus === LockState.OPENED
      ? record.lastOpeningTime && !isDateAfterNow(addMs(record.lastOpeningTime, record.openedTimeToAlert))
        ? 'bg-orange-500'
        : 'bg-yellow-100'
      : 'bg-green-500'
    : 'bg-rose-500';

export const hasBoxesWithAlert = (warehouse: WarehouseSummary): boolean =>
  !_.isEmpty(warehouse?.boxes.filter((box) => !box.isStateVerified));

export const hasBoxesWithAlertApproved = (warehouse: WarehouseSummary): boolean =>
  !_.isEmpty(
    warehouse?.boxes.filter(
      (box) =>
        box.isStateVerified &&
        box.lockStatus === LockState.OPENED &&
        box.lastOpeningTime &&
        !isDateAfterNow(addMs(box.lastOpeningTime, box.openedTimeToAlert)),
    ),
  );

export const hasOpenedBoxes = (warehouse: WarehouseSummary): boolean =>
  !_.isEmpty(warehouse?.boxes.filter((box) => box.lockStatus === LockState.OPENED));

export const hightLightWarehouseName = (warehouse: WarehouseSummary): string =>
  hasBoxesWithAlert(warehouse)
    ? 'bg-rose-500'
    : hasBoxesWithAlertApproved(warehouse)
    ? 'bg-orange-500'
    : hasOpenedBoxes(warehouse)
    ? 'bg-yellow-100'
    : '';

export const setOpenedCollapsesToLocalStorage = (warehouseKeys: string[]): CustomVoid => {
  localStorage.setItem('summary_opened_collapses', JSON.stringify(warehouseKeys));
};

export const getOpenedCollapsesToLocalStorage = (): string[] => JSON.parse(localStorage.getItem('summary_opened_collapses') || '[]');
