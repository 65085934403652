export enum HistoryActionName {
  box_open = 'box-open',
  box_close = 'box-close',
  box_open_attempt = 'box-open-attempt',
  box_open_long_time = 'box-open-long-time',
  box_unauthorized_access = 'box-unauthorized-access',
  door_open_attempt = 'warehouse-open-attempt',
  login_success = 'login-success',
  login_fail = 'login-fail',
  siren_turned_on = 'siren-turned-on',
  box_verify_state = 'box-verify-state',
}

export enum TargetType {
  BOX = 'box',
  WAREHOUSE = 'warehouse',
  USER = 'user',
  DOOR = 'door',
}
