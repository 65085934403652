import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useGetMeQuery } from 'features/auth/authApiSlice';
import { useGetAllWarehousesQuery, warehouseUtil } from 'features/warehouses/warehousesApiSlice';
import NoResultIcon from 'images/NoResult.svg';
import Header from 'shared/components/header/Header';
import Loader from 'shared/components/loader/Loader';
import { CustomVoid } from 'shared/types/generics';
import { isAdmin } from 'shared/utils/functions';

import WarehouseCard from './WarehouseCard';

const WarehouseList: FC = (props) => {
  const navigate = useNavigate();
  const { data } = useGetMeQuery();
  const { data: warehousesData, isLoading } = useGetAllWarehousesQuery('');

  const isCurrentUserAdmin = data && isAdmin(data?.role);

  const navigateToCreateWarehouse = useCallback((): CustomVoid => {
    navigate('/warehouses/settings');
  }, [navigate]);

  const getFilteredWarehouses = useCallback(() => {
    if (!isCurrentUserAdmin) {
      const filteredWarehouses = warehousesData?.filter(({ warehouseId }) => data?.assignedWarehouseIds.includes(warehouseId));

      return filteredWarehouses;
    } else {
      return warehousesData;
    }
  }, [data, isCurrentUserAdmin, warehousesData]);

  useEffect(() => {
    return () => {
      warehouseUtil.resetApiState();
    };
  }, []);

  const warehouses = getFilteredWarehouses();

  return (
    <div className="flex flex-col gap-2 mt-4">
      <div className="flex justify-between items-center">
        <Header title={<Typography.Title level={3}>Все склады</Typography.Title>} border={false}>
          {isCurrentUserAdmin && (
            <div>
              <Button
                icon={<PlusOutlined className="text-xl" />}
                type="primary"
                size="large"
                onClick={navigateToCreateWarehouse}
                style={{ fontWeight: '500', display: 'flex', alignItems: 'center' }}
              >
                Добавить склад
              </Button>
            </div>
          )}
        </Header>
      </div>
      <div className="grid grid-cols-4 gap-4 px-8">
        {warehouses?.map((warehouse) => (
          <WarehouseCard key={warehouse.warehouseId} warehouse={warehouse} isCurrentUserAdmin={isCurrentUserAdmin} />
        ))}
      </div>
      {warehouses?.length === 0 && (
        <div className="flex flex-col items-center gap-10">
          <div className="text-center">
            <Typography.Title level={3}>Список складов пуст !</Typography.Title>
            <Typography.Text type="secondary">Вы можете добавить склад, нажав на кнопку "Добавить склад"</Typography.Text>
          </div>
          <img alt="No result" src={NoResultIcon} className="w-2/5" />
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default WarehouseList;
