import { apiSlice } from 'api/apiSlice';
import { AUTH_LOGIN_PATH, AUTH_ME_PATH } from 'features/auth/constants';
import { CustomVoid } from 'shared/types/generics';

interface Login {
  email: string;
  password: string;
}

export interface JWTUser {
  userId: string;
  username: string;
  role: string;
  assignedWarehouseIds: string[];
  email: string;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Record<'refresh_token' | 'access_token', string>, Login>({
      query: (credentials) => ({
        url: AUTH_LOGIN_PATH,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getMe: builder.query<JWTUser, CustomVoid>({
      query: () => AUTH_ME_PATH,
      keepUnusedDataFor: 10,
    }),
  }),
});

export const { useLoginMutation, useGetMeQuery } = authApiSlice;
