/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { apiSlice } from 'api/apiSlice';
import { InternalEquipment, InternalEquipmentDocument } from 'features/interfaces/InternalEquipment';
import OpenLockDto from 'features/interfaces/OpenLockDto';
import { SirenStatus } from 'features/interfaces/SirenStatus';
import { Warehouse, WarehouseControllerHealth, WarehouseSummary } from 'features/interfaces/Warehouse';
import { WorkingHours } from 'features/interfaces/WorkingHours';
import {
  generateWarehouseAlertPath,
  generateWarehouseIsControllerHealthyPath,
  generateWarehouseSirenStatusPath,
  SUMMARY_BASE_PATH,
  WAREHOUSE_BASE_PATH,
  WAREHOUSE_INTERNAL_EQUIPMENT_BASE_PATH,
  WAREHOUSE_WORKING_HOURS,
} from 'features/warehouses/constants';
import { CustomVoid } from 'shared/types/generics';

export const warehousesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWarehouses: builder.query<Warehouse[], string>({
      query: () => WAREHOUSE_BASE_PATH,
      providesTags: (result) => ['Warehouses'],
    }),
    getWarehousesSummary: builder.query<WarehouseSummary[], CustomVoid>({
      query: () => SUMMARY_BASE_PATH,
      providesTags: (result) => ['Summary'],
    }),
    getAllExtendedWarehouses: builder.query<Warehouse[], string>({
      query: () => ({
        url: WAREHOUSE_BASE_PATH,
        params: { extended: true },
      }),
      providesTags: (result) => ['Warehouses'],
    }),
    getWarehouseByWarehouseId: builder.query<Warehouse, string>({
      query: (warehouseId: string) => ({
        url: `${WAREHOUSE_BASE_PATH}/${warehouseId}`,
      }),
      keepUnusedDataFor: 10,
      providesTags: (result) => ['Warehouses'],
    }),
    createWarehouse: builder.mutation<Warehouse, Warehouse>({
      query: (warehouse) => ({
        url: WAREHOUSE_BASE_PATH,
        method: 'POST',
        body: warehouse,
      }),
      invalidatesTags: ['Warehouses'],
    }),
    updateWarehouse: builder.mutation<Warehouse, Warehouse>({
      query: (warehouse: Warehouse) => ({
        url: `${WAREHOUSE_BASE_PATH}/${String(warehouse.initialWarehouseId)}`,
        method: 'PUT',
        body: warehouse,
      }),
      invalidatesTags: ['Warehouses'],
    }),
    deleteWarehouse: builder.mutation<Warehouse, string>({
      query: (warehouseId) => ({
        url: `${WAREHOUSE_BASE_PATH}/${warehouseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Warehouses'],
    }),
    openWarehouseDoor: builder.mutation<string, OpenLockDto>({
      query: (openLockDto: OpenLockDto) => ({
        url: `${WAREHOUSE_BASE_PATH}/${String(openLockDto.warehouseId)}/open`,
        method: 'PUT',
        body: openLockDto,
      }),
      invalidatesTags: ['Warehouses'],
    }),
    createInternalEquipment: builder.mutation<InternalEquipment, InternalEquipment>({
      query: (internalEquipment) => ({
        url: WAREHOUSE_INTERNAL_EQUIPMENT_BASE_PATH,
        method: 'POST',
        body: internalEquipment,
      }),
      invalidatesTags: ['Warehouses'],
    }),
    deleteInternalEquipment: builder.mutation<InternalEquipmentDocument, string>({
      query: (internalEquipmentId) => ({
        url: `${WAREHOUSE_INTERNAL_EQUIPMENT_BASE_PATH}/${internalEquipmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Warehouses'],
    }),
    editInternalEquipment: builder.mutation<InternalEquipmentDocument, InternalEquipmentDocument>({
      query: ({ internalEquipmentId, ...internalEquipment }) => ({
        url: `${WAREHOUSE_INTERNAL_EQUIPMENT_BASE_PATH}/${internalEquipmentId}`,
        body: internalEquipment,
        method: 'PUT',
      }),
      invalidatesTags: ['Warehouses'],
    }),
    createWorkingHours: builder.mutation<WorkingHours, WorkingHours>({
      query: (workingHours) => ({
        url: WAREHOUSE_WORKING_HOURS,
        method: 'POST',
        body: workingHours,
      }),
      invalidatesTags: ['Warehouses'],
    }),

    editWorkingHours: builder.mutation<WorkingHours, WorkingHours>({
      query: ({ warehouseId, ...workingHours }) => ({
        url: `${WAREHOUSE_WORKING_HOURS}/${warehouseId}`,
        method: 'PUT',
        body: workingHours,
      }),
      invalidatesTags: ['Warehouses'],
    }),
    turnAlertOn: builder.mutation<string, string>({
      query: (warehouseId: string) => ({
        url: generateWarehouseAlertPath(warehouseId),
        method: 'PUT',
      }),
    }),
    isControllerAvailable: builder.mutation<WarehouseControllerHealth, string>({
      query: (warehouseId: string) => ({
        url: generateWarehouseIsControllerHealthyPath(warehouseId),
        method: 'GET',
      }),
    }),
    getSirenStatus: builder.mutation<SirenStatus, string>({
      query: (warehouseId: string) => ({
        url: generateWarehouseSirenStatusPath(warehouseId),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllWarehousesQuery,
  useGetAllExtendedWarehousesQuery,
  useGetWarehousesSummaryQuery,
  useLazyGetWarehousesSummaryQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
  useOpenWarehouseDoorMutation,
  useCreateInternalEquipmentMutation,
  useEditInternalEquipmentMutation,
  useDeleteInternalEquipmentMutation,
  useGetWarehouseByWarehouseIdQuery,
  useCreateWorkingHoursMutation,
  useEditWorkingHoursMutation,
  useTurnAlertOnMutation,
  useGetSirenStatusMutation,
  useIsControllerAvailableMutation,
  util: warehouseUtil,
} = warehousesApiSlice;
