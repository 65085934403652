import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { App, Button, Card, Checkbox, Form, Input } from 'antd';
import { useLoginMutation } from 'features/auth/authApiSlice';
import { setCredentials } from 'features/auth/authSlice';
import { getUsernameFromToken, setAuthToken, setRefreshToken } from 'shared/helpers/accessTokenHelper';
import { useAppDispatch } from 'shared/hooks/hooks';
import { CustomAny, CustomVoid } from 'shared/types/generics';

import './Login.scss';

interface LoginFormValues {
  email: string;
  password: string;
  rememberUser: boolean;
}

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRef = useRef<HTMLInputElement>(null);
  const errRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const { message } = App.useApp();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [form] = Form.useForm();

  const [login, { isLoading }] = useLoginMutation();

  const showMessage = useCallback(
    (name: string): CustomVoid => {
      message.success(`Hello, ${name}!`)!;
    },
    [message],
  );

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  useEffect(() => {
    setErrorMessage('');
  }, []);

  const handleSubmit = useCallback(
    async (values: LoginFormValues): Promise<CustomVoid> => {
      const { email, password, rememberUser } = values;

      try {
        const { access_token: token, refresh_token: refresh } = await login({ email, password }).unwrap();
        const username = getUsernameFromToken(token);

        dispatch(setCredentials({ token, email, refresh, username }));
        setAuthToken(token, rememberUser);
        setRefreshToken(refresh, rememberUser);
        showMessage(email);

        navigate('/');
      } catch (error: CustomAny) {
        setErrorMessage(error?.data?.message || 'Ошибка при входе');
        errRef.current?.focus();
      }
    },
    [dispatch, login, navigate, showMessage],
  );

  return (
    <div className="h-screen flex flex-col justify-center items-center login-page">
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: '60px', color: '#ff6c48' }} />
      ) : (
        <Card className="login-card" title={<h2 className="text-center text-xl my-5">Вход в админ панель</h2>}>
          <Form name="basic" initialValues={{ rememberUser: true }} form={form} autoComplete="off" onFinish={handleSubmit}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Пожалуйста введите почту!' },
                { type: 'email', message: 'Введите корректную почту!' },
              ]}
            >
              <Input placeholder="Логин" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста введите пароль!' }, { min: 6 }]}>
              <Input.Password placeholder="Пароль" />
            </Form.Item>

            <Form.Item name="rememberUser" valuePropName="checked">
              <Checkbox>Запомнить меня</Checkbox>
            </Form.Item>

            <div ref={errRef} className="text-red-500 flex justify-center h-7">
              {errorMessage}
            </div>

            <Form.Item>
              <Button htmlType="submit" block>
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default Login;
