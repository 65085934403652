import { HistoryActionName, TargetType } from 'features/history/enums/HistoryActionName';
import { HISTORY_ACTION_TRANSLATES, HISTORY_TYPE_TRANSLATES } from 'shared/utils/constants';
import { ServerTableFilter as ServerTableFilterProp } from 'shared/components/table/interfaces';
import { Warehouse } from 'features/interfaces/Warehouse';
import { CustomAny } from 'shared/types/generics';

export const getHistoryTableFilters = (options: CustomAny = []): ServerTableFilterProp[] => [
  {
    type: 'Input',
    name: 'userName',
    column: 'user',
    props: {
      placeholder: 'Имя пользователя',
    },
  },
  {
    type: 'Input',
    name: 'boxName',
    column: 'targetType',
    props: {
      placeholder: 'Имя бокса',
      allowClear: true,
    },
  },
  {
    type: 'Select',
    name: 'warehouseId',
    column: 'warehouse',
    props: {
      placeholder: 'Выберите склад',
      mode: 'multiple',
      allowClear: true,
      options: [
        {
          value: 'null',
          label: 'Без склада',
        },
        ...(options?.warehouses?.map((warehouse: Warehouse) => {
          return {
            value: warehouse.warehouseId,
            label: warehouse.name,
          };
        }) || []),
      ],
    },
    style: { width: '100%' },
  },
  {
    type: 'Select',
    name: 'actionName',
    column: 'actionName',
    props: {
      mode: 'multiple',
      placeholder: 'Выберите событие',
      options: Object.values(HistoryActionName).map((actionName) => {
        return {
          value: actionName,
          label: HISTORY_ACTION_TRANSLATES[actionName] || actionName.replace('-', ''),
        };
      }),
    },
    style: { width: '100%' },
  },
  {
    type: 'Input',
    name: 'targetName',
    column: 'targetType',
    props: {
      placeholder: 'Название обьекта',
      allowClear: true,
    },
  },
  {
    type: 'Select',
    name: 'targetType',
    column: 'targetType',
    props: {
      mode: 'multiple',
      placeholder: 'Выберите тип обьекта',
      options: Object.values(TargetType).map((target) => {
        return {
          value: target,
          label: HISTORY_TYPE_TRANSLATES[target] || target,
        };
      }),
    },
    style: { width: '100%' },
  },
  {
    type: 'RangePicker',
    name: 'startDate,endDate',
    column: 'date',
    props: {
      showTime: true,
    },
    style: { width: '100%' },
  },
];

export const getWarehouseHistoryTableFilters = (): ServerTableFilterProp[] => [
  {
    type: 'Input',
    name: 'userName',
    column: 'user',
    props: {
      placeholder: 'Имя пользователя',
    },
  },
  {
    type: 'Input',
    name: 'boxName',
    column: 'targetType',
    props: {
      placeholder: 'Имя бокса',
      allowClear: true,
    },
  },
  {
    type: 'Select',
    name: 'actionName',
    column: 'actionName',
    props: {
      mode: 'multiple',
      placeholder: 'Выберите событие',
      options: Object.values(HistoryActionName).map((actionName) => {
        return {
          value: actionName,
          label: HISTORY_ACTION_TRANSLATES[actionName] || actionName.replace('-', ''),
        };
      }),
    },
    style: { width: '100%' },
  },
  {
    type: 'Input',
    name: 'targetName',
    column: 'targetType',
    props: {
      placeholder: 'Название обьекта',
      allowClear: true,
    },
  },
  {
    type: 'Select',
    name: 'targetType',
    column: 'targetType',
    props: {
      mode: 'multiple',
      placeholder: 'Выберите тип обьекта',
      options: Object.values(TargetType).map((target) => {
        return {
          value: target,
          label: HISTORY_TYPE_TRANSLATES[target] || target,
        };
      }),
    },
    style: { width: '100%' },
  },
  {
    type: 'RangePicker',
    name: 'startDate,endDate',
    column: 'date',
    props: {
      showTime: true,
    },
  },
];
