import * as React from 'react';

interface TimeProps {
  beginning: number;
  unit: string;
}

const Timer: React.FC<TimeProps> = (props) => {
  const { beginning, unit } = props;

  const [time, setTime] = React.useState(beginning);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {time} {unit}
    </div>
  );
};

export default Timer;
