import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

const NotFoundPage: FC = () => (
  <div className="p-[5%]">
    <Result
      status="404"
      title="404"
      subTitle="Извините, страница, которую вы посетили, не существует."
      extra={
        <Link to="/">
          <Button type="primary" size="large">
            Вернуться назад
          </Button>
        </Link>
      }
    />
  </div>
);

export default NotFoundPage;
