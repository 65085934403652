import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface BackButtonProps {
  path: string;
}

const BackButton: FC<BackButtonProps> = (props) => {
  const { path } = props;

  return (
    <Link to={path} className="warehouse-icon-active">
      <ArrowLeftOutlined className="text-xl font-medium text-cyan-600" />
      <span className="text-base font-medium ml-3 text-cyan-600">Назад</span>
    </Link>
  );
};

export default BackButton;
