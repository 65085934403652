/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Spin, Table } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import classNames from 'classnames';
import { AnyObject, CustomAny } from 'shared/types/generics';

import './Table.scss';
import _ from 'lodash';

interface TableProps {
  data?: AnyObject[];
  columns: ColumnsType<CustomAny>;
  loading?: boolean;
  bordered?: boolean;
  className?: string;
  rowKey: string;
  search?: boolean;
  searchField?: string;
  searchPlaceholder?: string;
  selectRows?: boolean;
  pagination?: false | TablePaginationConfig | undefined;
  size?: SizeType;
  rowClassName?: (record: CustomAny, index: number) => string;
}

const CustomTable: FC<TableProps> = (props) => {
  const {
    columns,
    data,
    className,
    bordered = true,
    rowKey = '',
    rowClassName = '',
    search = false,
    searchField = '',
    searchPlaceholder = 'Поиск',
    selectRows = false,
    pagination = false,
    size = 'large',
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<AnyObject[]>();
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (search) {
      const filteredData = data?.filter((element) => element[searchField].includes(searchText));
      setDataSource(filteredData);
    }
  }, [searchText, search]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const onSearch = (e: CustomAny): void => {
    setSearchText(e.target.value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className={classNames('table-component w-full sm:px-6 lg:px-8', className)}>
      <div className="filter-container flex flex-1 items-center">
        {search && (
          <Input
            className="table-search-input ml-auto"
            placeholder={searchPlaceholder}
            size="large"
            prefix={<SearchOutlined className="search-icon" />}
            onPressEnter={onSearch}
            allowClear
          />
        )}
      </div>
      <Table
        loading={_.isUndefined(dataSource)}
        className={classNames(bordered ? 'border-solid border border-gray-200 rounded-lg' : '')}
        columns={columns}
        rowClassName={rowClassName}
        dataSource={dataSource}
        rowKey={(record) => record[rowKey]}
        pagination={pagination}
        size={size}
        rowSelection={selectRows ? rowSelection : undefined}
        sticky
      />
    </div>
  );
};

export default CustomTable;
