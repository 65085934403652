import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { App } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { useExportHistoryMutation, useGetHistoryQuery } from 'features/history/historyApiSlice';
import { HistoryDocument } from 'features/interfaces/HistoryDocument';
import { useGetAllExtendedWarehousesQuery } from 'features/warehouses/warehousesApiSlice';
import { ServerTableActions } from 'shared/components/table/interfaces';
import { WebSocketContext } from 'shared/utils/websocketcontext';
import ServerTable from 'shared/components/table/ServerTable';
import { exportXlsx } from 'shared/helpers/exelHelper';
import { getQueryErrorMessage } from 'shared/helpers/queryErrorMessageHelper';
import { RtkQueryPaginatedFunction } from 'shared/types/generics';

import { HISTORY_TABLE_COLUMNS } from './constants/table';
import { getHistoryTableFilters } from './helpers/getTableFilters';

const HistoryPage: FC = (props) => {
  const socket = useContext(WebSocketContext);
  const { notification } = App.useApp();
  const [exportActions, { isLoading }] = useExportHistoryMutation();
  const [newAction, setNewAction] = useState<HistoryDocument>();

  const { data: warehousesData } = useGetAllExtendedWarehousesQuery('');

  useEffect(() => {
    if (!_.isNull(socket)) {
      socket.on('onHistoryAction', (newMessage: HistoryDocument) => {
        setNewAction(newMessage);
      });
      return () => {
        socket.off('onHistoryAction');
      };
    }
  }, [socket]);

  const handleExport = useCallback(
    async (actions: HistoryDocument[]) => {
      const result = await exportActions(actions);

      if ('error' in result) {
        notification.error({
          message: 'Ошибка',
          description: getQueryErrorMessage(result.error),
          placement: 'bottomRight',
        });
      } else {
        const bufferObject = result.data;

        exportXlsx(bufferObject.data, 'skladovka-history');
      }
    },
    [exportActions, notification],
  );

  const tableActions: Array<ServerTableActions<HistoryDocument>> = useMemo(
    () => [
      {
        name: 'Скачать историю',
        icon: <CloudDownloadOutlined />,
        loading: isLoading,
        onClick: (actions) => {
          void handleExport(actions);
        },
      },
    ],
    [handleExport, isLoading],
  );

  const selectOptions = useMemo(() => {
    return { warehouses: warehousesData };
  }, [warehousesData]);

  const filters = getHistoryTableFilters(selectOptions);

  return (
    <section className="history-page">
      <ServerTable
        // title="Журнал событий"
        className="mb-6"
        columns={HISTORY_TABLE_COLUMNS}
        rowKey="actionId"
        fetchData={useGetHistoryQuery as RtkQueryPaginatedFunction}
        scroll={{ y: document.documentElement.clientHeight - 350 }}
        loading={false}
        filters={filters}
        newElement={newAction}
        tableActions={tableActions}
        applyQueryFilters
        // optional : implement onRowHover(elem => ...)
      />
    </section>
  );
};

export default HistoryPage;
