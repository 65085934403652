import dayjs, { Dayjs } from 'dayjs';

import { ROLES_MAP } from './constants';

export const isAdmin = (role: string): boolean => [ROLES_MAP.superUser].includes(role);

export const isManager = (role: string): boolean => [ROLES_MAP.manager].includes(role);

export const castFromMinToMs = (min: number): number => min * 60000;

export const castFromMsToMin = (ms: number): string => parseFloat((ms / 60000).toFixed(2)).toString();

export const addMs = (date: string, ms: number): Dayjs => dayjs(date).add(ms, 'ms');

export const isDateAfterNow = (leftDate: Dayjs): boolean => leftDate.isAfter(dayjs());
