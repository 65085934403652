import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'antd';
import { logout, selectCurrentRefresh, selectCurrentUser } from 'features/auth/authSlice';
import AppHeader from 'shared/components/header/Header';
import { useAppDispatch } from 'shared/hooks/hooks';

const RequireAuth: FC = () => {
  const dispatch = useAppDispatch();
  const refresh = useSelector(selectCurrentRefresh);
  const username = useSelector(selectCurrentUser);
  const location = useLocation();

  const handleLogout = (): AnyAction => dispatch(logout());
  return refresh ? (
    <>
      <AppHeader
        className="z-index-10"
        dark
        title={
          <div className="flex flex-row space-x-3">
            <NavLink to="/" className="flex flex-row">
              <h1 className="text-white">Главная</h1>
            </NavLink>
            <NavLink to="/users">
              <h1 className="text-white">Пользователи</h1>
            </NavLink>
            <NavLink to="/warehouses">
              <h1 className="text-white">Склады</h1>
            </NavLink>
          </div>
        }
      >
        <div>{username}</div>
        <Button ghost onClick={handleLogout}>
          Выйти
        </Button>
      </AppHeader>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default RequireAuth;
