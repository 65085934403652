import { JWTUser } from 'features/auth/authApiSlice';
import jwt_decode from 'jwt-decode';
import { REFRESH_KEY, USER_KEY } from 'shared/constants/application';
import { AnyObject, CustomVoid } from 'shared/types/generics';

export const setAuthToken = (data: string, remember: boolean): CustomVoid => {
  remember ? localStorage.setItem(USER_KEY, data) : sessionStorage.setItem(USER_KEY, data);
};

export const setRefreshToken = (data: string, remember: boolean): CustomVoid => {
  remember && localStorage.setItem(REFRESH_KEY, data);
};

export const updateAuthToken = (data: string): CustomVoid => {
  localStorage.getItem(USER_KEY) ? localStorage.setItem(USER_KEY, data) : sessionStorage.setItem(USER_KEY, data);
};

export const clearAuthToken = (): CustomVoid => {
  sessionStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_KEY);
};

export const clearRefreshToken = (): CustomVoid => {
  localStorage.removeItem(REFRESH_KEY);
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_KEY);
};

export const getUsernameFromToken = (token?: string): string | null => {
  const authToken = token || getAuthToken();

  return authToken ? jwt_decode<AnyObject>(authToken).username : null;
};

export const getEmailFromToken = (token?: string): string | null => {
  const authToken = token || getAuthToken();

  return authToken ? jwt_decode<AnyObject>(authToken).email : null;
};

export const getInfoFromToken = (token?: string): JWTUser | null => {
  const authToken = token || getAuthToken();

  return authToken ? jwt_decode<JWTUser>(authToken) : null;
};

export const isAuthKeyEmpty = (): boolean => !getAuthToken();
