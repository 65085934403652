import React, { FC } from 'react';
import ReactInputMask from 'react-input-mask';
import { CloseCircleOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, TimePicker, Typography } from 'antd';

import { Warehouse } from 'features/interfaces/Warehouse';
import Header from 'shared/components/header/Header';
import { commonStyles } from 'shared/styles/styles';
import { CustomVoid, Nullable } from 'shared/types/generics';

import './Warehouse.scss';

interface WarehouseFormValues {
  warehouse: Nullable<Warehouse>;
  showDeleteConfirm: (warehouseId: string) => CustomVoid;
  handleOpenWarehouseDoor: (warehouse: number) => CustomVoid;
  handleCreateInternalEquipment: (equipmentNumber: number) => CustomVoid;
  handleDeleteInternalEquipment: (equipmentNumber: number) => CustomVoid;
  handleCreateWorkingHours: () => CustomVoid;
  isOpenWarehouseDoorLoading: boolean;
  isDeleteWarehouseLoading: boolean;
  isUpdateWarehouseLoading: boolean;
  isCreateWarehouseLoading: boolean;
  isSavedChanges: boolean;
}

const WarehouseForm: FC<WarehouseFormValues> = (props) => {
  const {
    warehouse,
    showDeleteConfirm,
    handleOpenWarehouseDoor,
    isCreateWarehouseLoading,
    isUpdateWarehouseLoading,
    isOpenWarehouseDoorLoading,
    handleCreateInternalEquipment,
    isDeleteWarehouseLoading,
    isSavedChanges,
    handleDeleteInternalEquipment,
    handleCreateWorkingHours,
  } = props;

  return (
    <div>
      <div className="flex justify-between items-center">
        <Header border={false} title={<Typography.Title level={2}>{warehouse ? 'Настройка склада' : 'Создание склада'}</Typography.Title>}>
          <Button
            icon={<SaveOutlined className="text-xl" />}
            type="primary"
            htmlType="submit"
            size="large"
            style={commonStyles.actionButton}
            loading={isUpdateWarehouseLoading || isCreateWarehouseLoading}
            disabled={isSavedChanges}
          >
            Сохранить
          </Button>
        </Header>
      </div>
      <div className="flex px-3">
        <div className="flex-1 px-5 w-2/4">
          <Typography.Title level={3}>Основная информация</Typography.Title>
          <Divider />
          <Form.Item
            style={{ width: '75%' }}
            name="name"
            label="Название"
            rules={[{ required: true, message: 'Пожалуйста, введите название склада!' }]}
          >
            <Input placeholder="Пожалуйста, введите название склада" size="large" />
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="city"
            label="Город"
            rules={[{ required: true, message: 'Пожалуйста, введите город склада!' }]}
          >
            <Input placeholder="Пожалуйста, введите город склада" size="large" />
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="address"
            label="Адрес"
            rules={[{ required: true, message: 'Пожалуйста, введите адрес склада!' }]}
          >
            <Input placeholder="Пожалуйста, введите адрес склада" size="large" />
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="warehouseId"
            label="WarehouseID склада"
            rules={[{ required: true, message: 'Пожалуйста, введите WarehouseID cклада!' }]}
          >
            <Input placeholder="Пожалуйста, введите WarehouseID cклада" size="large" />
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="email"
            label="Почта филиала"
            rules={[
              { required: true, message: 'Пожалуйста, введите почту филиала!' },
              { type: 'email', message: 'Пожалуйста, введите корректную почту!' },
            ]}
          >
            <Input placeholder="Пожалуйста, введите почту филиала" size="large" />
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="alertDuration"
            label="Длительность сирены (секунды)"
            rules={[{ required: true, message: 'Пожалуйста, введите длительность сирены!' }]}
          >
            <ReactInputMask mask="999" maskPlaceholder={null}>
              <Input placeholder="Длительность сирены в секундах" size="large" />
            </ReactInputMask>
          </Form.Item>

          <Form.Item
            style={{ width: '75%' }}
            name="alertLockNumber"
            label="Номер замка сирены"
            rules={[{ required: true, message: 'Пожалуйста, введите номер замка сирены!' }]}
          >
            <ReactInputMask mask="*:99:99" maskPlaceholder={null}>
              <Input placeholder="Номер замка сирены" size="large" />
            </ReactInputMask>
          </Form.Item>

          <div className="flex justify-between w-3/4">
            <Form.Item
              style={{ width: '50%' }}
              name="wifiNetworkName"
              label="Имя сети Wi-Fi"
              rules={[{ required: true, message: 'Пожалуйста, введите имя сети Wi-Fi!' }]}
            >
              <Input placeholder="Пожалуйста, введите имя сети Wi-Fi!" size="large" />
            </Form.Item>

            <Form.Item
              style={{ width: '45%' }}
              name="wifiPassword"
              label="Пароль Wi-Fi"
              rules={[{ required: true, message: 'Пожалуйста, введите пароль Wi-Fi!' }]}
            >
              <Input placeholder="Пожалуйста, введите пароль Wi-Fi!" size="large" minLength={8} />
            </Form.Item>
          </div>
          <div className="flex w-3/4 gap-3">
            <Form.Item
              style={{ width: '55%' }}
              name="routerIp"
              label="IP адрес"
              rules={[{ required: true, message: 'Пожалуйста, введите IP адрес!' }]}
            >
              <Input placeholder="Пожалуйста, введите IP адрес!" size="large" minLength={10} />
            </Form.Item>
            <span className="self-center">:</span>
            <Form.Item
              style={{ width: '45%' }}
              name="servicePort"
              label="Порт"
              rules={[{ required: true, message: 'Пожалуйста, введите порт!' }]}
            >
              <Input placeholder="Пожалуйста, введите порт!" size="large" maxLength={5} minLength={4} />
            </Form.Item>
          </div>

          <Form.Item style={{ width: '75%' }}>
            <div className={`flex ${warehouse ? 'justify-between' : 'justify-end'} mt-4`}>
              {warehouse && (
                <Button
                  type="primary"
                  danger
                  size="large"
                  style={commonStyles.actionButton}
                  icon={<DeleteOutlined className="text-xl" />}
                  onClick={() => {
                    showDeleteConfirm(warehouse?.warehouseId);
                  }}
                  loading={isDeleteWarehouseLoading}
                >
                  Удалить склад
                </Button>
              )}
            </div>
          </Form.Item>
        </div>
        <div className="flex-1 px-5">
          <Typography.Title level={3}>Управление</Typography.Title>
          <Form.List name="internalEquipment">
            {(internalEquipment, { add, remove }) => {
              return (
                <div>
                  {Boolean(internalEquipment.length) && <Divider orientation="left">Двери</Divider>}
                  {internalEquipment?.map((equipment, index) => (
                    <div className="flex gap-5" key={equipment.key}>
                      <Form.Item
                        name={[index, 'name']}
                        label="Название"
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите название двери!',
                          },
                        ]}
                        style={{ width: '30%' }}
                      >
                        <Input placeholder="Введите название двери" size="large" />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'lockNumber']}
                        label="Номер замка"
                        style={{ width: '30%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите номер замка!',
                          },
                          { len: 7, message: 'Введите корректный номер замка!' },
                        ]}
                      >
                        <ReactInputMask mask="*:99:99" maskPlaceholder={null}>
                          <Input placeholder="Введите номер замка" size="large" className="w-75" />
                        </ReactInputMask>
                      </Form.Item>
                      {Boolean(internalEquipment.length) && (
                        <div className="flex mt-1 gap-4 self-center">
                          {warehouse?.internalEquipment && warehouse?.internalEquipment[equipment.name] ? (
                            <DeleteOutlined
                              style={{ fontSize: '24px', color: 'red' }}
                              className="mr-5 warehouse-icon-active"
                              onClick={() => {
                                handleDeleteInternalEquipment(equipment.name);
                              }}
                            />
                          ) : (
                            <CloseCircleOutlined
                              style={{ fontSize: '24px', color: 'red' }}
                              className="mr-5 warehouse-icon-active"
                              onClick={() => {
                                remove(equipment.name);
                              }}
                            />
                          )}

                          {warehouse?.internalEquipment && !warehouse?.internalEquipment[equipment.name] && (
                            <SaveOutlined
                              style={{ fontSize: '24px', color: '#289aca' }}
                              className="mr-5 warehouse-icon-active"
                              onClick={() => {
                                handleCreateInternalEquipment(equipment.name);
                              }}
                            />
                          )}

                          {warehouse?.warehouseId && warehouse?.internalEquipment && warehouse?.internalEquipment[equipment.name] && (
                            <Button
                              size="large"
                              loading={isOpenWarehouseDoorLoading}
                              onClick={() => {
                                handleOpenWarehouseDoor(equipment.name);
                              }}
                            >
                              Открыть
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button type="dashed" size="large" onClick={add} style={{ width: '60%' }} disabled={!warehouse}>
                      Добавить дверь
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <div className="flex-1 px-5">
            <Divider orientation="left">Время работы склада</Divider>
            <Form.Item name={['workingHours', 'from']} label="Время открытия склада" style={{ width: '50%' }}>
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Form.Item name={['workingHours', 'till']} label="Время закрытия склада" style={{ width: '50%' }}>
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Button onClick={handleCreateWorkingHours}>Подтвердить</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseForm;
