import { ColumnsType } from 'antd/es/table';
import { UserDocument } from 'features/interfaces/UserDocument';

export const USERS_TABLE_COLUMNS: ColumnsType<UserDocument> = [
  {
    title: 'Имя пользователя',
    dataIndex: 'username',
    key: 'username',
    render: (username, record) => (
      <div className="flex items-center">
        <p>{username}</p>
      </div>
    ),
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
    render: (email, record) => (
      <div className="flex items-center">
        <p>{email}</p>
      </div>
    ),
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
    width: '10%',
  },
];
