import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { App, Badge, Button, Collapse, Popover, Spin } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { useGetWarehousesSummaryQuery } from 'features/warehouses/warehousesApiSlice';
import { useOpenBoxMutation, useVerifyStateMutation } from 'features/boxes/boxesApiSlice';
import { AnyFunction, CustomVoid } from 'shared/types/generics';
import WarehouseLocks from 'pages/boxes/components/WarehouseLocks';
import { LockState } from 'features/enums/lockState.enum';
import { JWTUser, useGetMeQuery } from 'features/auth/authApiSlice';
import { getQueryErrorMessage } from 'shared/helpers/queryErrorMessageHelper';
import { HistoryDocument } from 'features/interfaces/HistoryDocument';
import { HISTORY_ACTION_TRANSLATES } from 'shared/utils/constants';
import WarehouseSiren from 'pages/boxes/components/WarehouseSiren';

import {
  countBoxesWithAlert,
  generateAlertCounter,
  getOpenedCollapsesToLocalStorage,
  hasBoxesWithAlert,
  highlightRowSummary,
  hightLightWarehouseName,
  setOpenedCollapsesToLocalStorage,
} from '../utils';

import './Summary.scss';
import { BoxDocument, BoxWithHistory } from 'features/interfaces/Box';

interface SummaryProps {
  setActiveKey: AnyFunction;
}

const Summary: React.FC<SummaryProps> = ({ setActiveKey }) => {
  const [openedWarehousesCollapse, setOpenedWarehousesCollapse] = useState<string[]>(getOpenedCollapsesToLocalStorage());
  const { data: userData } = useGetMeQuery();
  const { data: summary } = useGetWarehousesSummaryQuery();
  const [, setSearchParams] = useSearchParams();
  const [verifyState] = useVerifyStateMutation();
  const [openBox] = useOpenBoxMutation();
  const { notification, modal } = App.useApp();

  const [popoverOpenKey, setPopoverOpenKey] = useState<string | null>(null);

  const hidePopover = (): void => {
    setPopoverOpenKey(null);
  };

  const handleOpenChange = (key: string | null): void => {
    setPopoverOpenKey(key);
  };

  const handleVerifyState = useCallback(
    async (boxId: string): Promise<CustomVoid> => {
      await verifyState({ boxId, isStateVerified: true });
    },
    [verifyState],
  );

  const handleOpenedWarehousesCollapse = (warehousesKeys?: string[] | string): CustomVoid => {
    setOpenedWarehousesCollapse((warehousesKeys as string[]) || []);
    setOpenedCollapsesToLocalStorage((warehousesKeys as string[]) || []);
  };

  const handleOpenBox = useCallback(
    async (boxId: string, boxNumber: string): Promise<CustomVoid> => {
      modal.confirm({
        title: `Открытие бокса ${boxNumber}`,
        content: 'Вы уверены, что хотите открыть бокс?',
        onOk: async () => {
          const result = await openBox({ boxId, userId: (userData as JWTUser).userId });
          if ('error' in result) {
            notification.error({
              message: 'Ошибка',
              description: getQueryErrorMessage(result.error),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: 'Успешно',
              placement: 'bottomRight',
            });
          }
        },
      });
    },
    [openBox, userData, modal, notification],
  );

  const summaryData = useMemo(
    () =>
      summary?.map((warehouse) => ({
        key: warehouse.warehouseId,
        label: (
          <div className="flex flex-row justify-between items-center ">
            <span className={`p-2 rounded ${hightLightWarehouseName(warehouse)}`}>
              {`${warehouse.name} (${generateAlertCounter(countBoxesWithAlert(warehouse.boxes))})`}
            </span>
            {warehouse.alertLockNumber && <WarehouseSiren warehouseId={warehouse.warehouseId} />}
          </div>
        ),
        children: (
          <div>
            <WarehouseLocks locks={warehouse.internalEquipment} warehouseId={warehouse.warehouseId} className="p-3 grid grid-cols-2" />
            <div className="grid grid-cols-8">
              {warehouse.boxes?.map((box: BoxWithHistory) => (
                <Popover
                  open={popoverOpenKey === box.boxId}
                  trigger="click"
                  key={box.boxId}
                  onOpenChange={() => {
                    handleOpenChange(box.boxId);
                  }}
                  title={box.boxNumber}
                  content={
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <Badge
                          text={`${
                            box.lockStatus === LockState.OPENED
                              ? `открыт с ${dayjs(box.lastOpeningTime).format('DD/MM/YYYY HH:mm')}`
                              : box.lockStatus === LockState.CLOSED
                              ? 'закрыт'
                              : 'нет информации'
                          }`}
                          color={box.lockStatus === LockState.OPENED ? 'green' : box.lockStatus === LockState.CLOSED ? 'red' : 'yellow'}
                        />
                        {box.lockStatus === LockState.CLOSED && (
                          <Button
                            type="ghost"
                            className="box-lock-custom-icon"
                            shape="circle"
                            onClick={async () => {
                              await handleOpenBox(box.boxId, box.boxNumber);
                            }}
                            icon={<UnlockOutlined style={{ fontSize: 15, fontWeight: 'bold', cursor: 'pointer' }} />}
                          />
                        )}
                      </div>
                      <span>{box.customerName || 'Свободен'}</span>
                      {box?.history?.map((history: HistoryDocument) => (
                        <div key={history.createdAt}>
                          <span>
                            {dayjs(history.createdAt).format('DD/MM/YYYY HH:mm')} {HISTORY_ACTION_TRANSLATES[history.actionName]}
                          </span>
                        </div>
                      ))}
                      <div>
                        <Button
                          onClick={() => {
                            hidePopover();
                            setSearchParams(
                              new URLSearchParams({
                                warehouseId: warehouse.warehouseId,
                                boxName: box.boxNumber,
                                startDate: dayjs().startOf('day').toISOString(),
                                endDate: dayjs().endOf('day').toISOString(),
                              }),
                            );
                            setActiveKey('1');
                          }}
                        >
                          Перейти к журналу
                        </Button>
                        <Button
                          type="link"
                          onClick={() => {
                            handleOpenChange(null);
                          }}
                        >
                          закрыть
                        </Button>
                      </div>
                      {!box.isStateVerified && (
                        <Button
                          onClick={async () => {
                            await handleVerifyState(box.boxId);
                          }}
                          className="ml-1"
                        >
                          Подтвердить
                        </Button>
                      )}
                    </div>
                  }
                >
                  <span className={`${highlightRowSummary(box)} text-center cursor-pointer`} key={box.boxId}>
                    {box.boxNumber}
                  </span>
                </Popover>
              ))}
            </div>
          </div>
        ),
      })),
    [summary, popoverOpenKey, handleOpenBox, handleVerifyState, setActiveKey, setSearchParams],
  );

  return (
    <div className="px-5">
      <div className="space-x-3 mb-6">
        <Button
          onClick={() => {
            handleOpenedWarehousesCollapse(summary?.map((warehouse) => warehouse.warehouseId));
          }}
        >
          Развернуть все филиалы
        </Button>
        <Button
          onClick={() => {
            handleOpenedWarehousesCollapse(
              summary?.filter((warehouse) => hasBoxesWithAlert(warehouse)).map((warehouse) => warehouse.warehouseId),
            );
          }}
        >
          Развернуть филиалы с тревогой
        </Button>
        <Button
          onClick={() => {
            handleOpenedWarehousesCollapse([]);
          }}
        >
          Свернуть все филиалы
        </Button>
      </div>
      <div className="flex justify-center">{_.isEmpty(summary) && <Spin size="large" style={{ fontSize: 36 }} />}</div>
      <Collapse
        items={summaryData}
        activeKey={openedWarehousesCollapse}
        className="grid grid-cols-3 mt-3 space-x-3 border rounded"
        onChange={handleOpenedWarehousesCollapse}
      />
    </div>
  );
};

export default Summary;
