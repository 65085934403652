import { HistoryActionName, TargetType } from '../../features/history/enums/HistoryActionName';
import { castFromMsToMin } from './functions';

export const ROLES_MAP = {
  superUser: 'superuser',
  manager: 'manager',
};

export const HISTORY_ACTION_TRANSLATES: Record<string, string> = {
  [HistoryActionName.box_open]: 'Открытие бокса',
  [HistoryActionName.box_close]: 'Закрытие бокса',
  [HistoryActionName.box_open_attempt]: 'Попытка открыть бокс',
  [HistoryActionName.door_open_attempt]: 'Попытка открыть склад',
  [HistoryActionName.login_success]: 'Успешная авторизация',
  [HistoryActionName.login_fail]: 'Неуспешная авторизация',
  [HistoryActionName.siren_turned_on]: 'Включение сирены',
  [HistoryActionName.box_open_long_time]: 'Бокс слишком долго открыт',
  [HistoryActionName.box_unauthorized_access]: 'Несанкционированный доступ к боксу',
  [HistoryActionName.box_verify_state]: 'Подтверждение состояния бокса',
};

export const HISTORY_TYPE_TRANSLATES: Record<string, string> = {
  [TargetType.BOX]: 'бокс',
  [TargetType.USER]: 'пользователь',
  [TargetType.WAREHOUSE]: 'склад',
  [TargetType.DOOR]: 'дверь',
};

export const HISTORY_ACTION_TYPE_TRANSLATES: Record<string, (value: number) => string> = {
  [HistoryActionName.box_open_long_time]: (boxTimeToAlert: number): string => `открыт более ${castFromMsToMin(boxTimeToAlert)} мин`,
};
