import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { App, Form, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Warehouse } from 'features/interfaces/Warehouse';
import { WorkingHours } from 'features/interfaces/WorkingHours';
import {
  useCreateInternalEquipmentMutation,
  useCreateWarehouseMutation,
  useDeleteInternalEquipmentMutation,
  useDeleteWarehouseMutation,
  useEditInternalEquipmentMutation,
  useEditWorkingHoursMutation,
  useGetAllExtendedWarehousesQuery,
  useOpenWarehouseDoorMutation,
  useUpdateWarehouseMutation,
} from 'features/warehouses/warehousesApiSlice';
import _ from 'lodash';
import WarehouseForm from 'pages/warehouses/WarehouseForm';
import BackButton from 'shared/components/backButton/BackButton';
import Header from 'shared/components/header/Header';
import { CustomAny, CustomVoid, Nullable } from 'shared/types/generics';

import './Warehouse.scss';
import { InternalEquipmentDocument } from 'features/interfaces/InternalEquipment';

const { confirm } = Modal;

const WarehousePage: FC = (props) => {
  const { state } = useLocation();
  const warehouseId: string = state?.warehouseId;

  const navigate = useNavigate();

  const { notification } = App.useApp();
  const [form] = Form.useForm();

  const [isSavedChanges, setSavedChanges] = useState<boolean>(true);
  const [warehouse, setWarehouse] = useState<Nullable<Warehouse>>(null);

  const { data: warehousesData } = useGetAllExtendedWarehousesQuery('');
  const [deleteWarehouse, { isLoading: isDeleteWarehouseLoading }] = useDeleteWarehouseMutation();
  const [openWarehouseDoor, { isLoading: isOpenWarehouseDoorLoading }] = useOpenWarehouseDoorMutation();
  const [updateWarehouse, { isLoading: isUpdateWarehouseLoading }] = useUpdateWarehouseMutation();
  const [createWarehouse, { isLoading: isCreateWarehouseLoading }] = useCreateWarehouseMutation();
  const [createInternalEquipment] = useCreateInternalEquipmentMutation();
  const [deleteInternalEquipment] = useDeleteInternalEquipmentMutation();
  const [editInternalEquipment] = useEditInternalEquipmentMutation();
  const [editWorkingHours] = useEditWorkingHoursMutation();

  const initialWarehouseValues: Warehouse = useMemo(() => {
    return {
      name: warehouse?.name || '',
      address: warehouse?.address || '',
      warehouseId: warehouse?.warehouseId || '',
      city: warehouse?.city || '',
      email: warehouse?.email || '',
      wifiNetworkName: warehouse?.wifiNetworkName || '',
      wifiPassword: warehouse?.wifiPassword || '',
      routerIp: warehouse?.routerIp || '',
      servicePort: warehouse?.servicePort || '',
      internalEquipment: warehouse?.internalEquipment || [],
      alertDuration: (parseInt(warehouse?.alertDuration || '') / 1000).toFixed(),
      alertLockNumber: warehouse?.alertLockNumber || '',
      workingHours: {
        from: warehouse?.workingHours ? dayjs(warehouse?.workingHours?.from, 'HH:mm') : '',
        till: warehouse?.workingHours ? dayjs(warehouse?.workingHours?.till, 'HH:mm') : '',
      },
      initialWarehouseId: warehouse?.warehouseId || '',
    };
  }, [warehouse]);

  useEffect(() => {
    const warehouse = warehousesData?.find((warehouse) => String(warehouse.warehouseId) === String(warehouseId));

    if (warehouse) {
      setWarehouse(warehouse);
      form.setFieldsValue(initialWarehouseValues);
    } else {
      setWarehouse(null);
    }
  }, [warehouseId, warehousesData, form, initialWarehouseValues]);

  const handleSubmit = useCallback(
    async (formData: Warehouse) => {
      let result;
      if (!_.isEqual(formData.internalEquipment, initialWarehouseValues.internalEquipment)) {
        const internalEquipmentsToUpdate = _.differenceWith(
          formData.internalEquipment,
          initialWarehouseValues.internalEquipment || [],
          _.isEqual,
        );

        _.forEach(internalEquipmentsToUpdate, async (internalEquipment: InternalEquipmentDocument) => {
          await editInternalEquipment(internalEquipment);
        });
      }

      const warehouseDto: Warehouse = {
        ...warehouse,
        ...formData,
        alertDuration: (parseInt(formData.alertDuration) * 1000).toFixed(),
        initialWarehouseId: warehouseId,
      };

      if (warehouseId) {
        result = await updateWarehouse(warehouseDto);
      } else {
        result = await createWarehouse(warehouseDto);
      }

      if ('error' in result) {
        notification.error({
          message: `Возникла ошибка при ${warehouse ? 'обновлении' : 'cоздании'} склада`,
          placement: 'top',
        });
      } else {
        navigate('/warehouses');

        notification.success({
          message: `Cклад успешно ${warehouse ? 'обновлен' : 'создан'}`,
          placement: 'top',
        });
      }
    },
    [updateWarehouse, createWarehouse, notification, warehouse, warehouseId, navigate],
  );

  const handleDeleteWarehouse = useCallback(
    async (warehouseId: string) => {
      const result = await deleteWarehouse(warehouseId);

      if ('error' in result) {
        notification.error({
          message: 'Произошла ошибка при удалении склада',
          placement: 'top',
        });
      } else {
        navigate('/warehouses');

        notification.success({
          message: 'Склад успешно удалён',
          placement: 'top',
        });
      }
    },
    [notification, deleteWarehouse, navigate],
  );

  const handleOpenWarehouseDoor = useCallback(
    async (equipmentNumber: number) => {
      if (warehouse) {
        const formValues: Warehouse = form.getFieldsValue();

        const { internalEquipment } = formValues;

        const { warehouseId, routerIp, servicePort } = warehouse;

        const protocol: string = process.env.NODE_ENV === 'development' ? 'http' : 'https';

        if (internalEquipment) {
          const openLockDto = {
            warehouseId,
            controllerUrl: `${protocol}://${routerIp}:${servicePort}`,
            internalEquipmentId: internalEquipment[equipmentNumber].internalEquipmentId,
          };

          const result = await openWarehouseDoor(openLockDto);

          if ('error' in result) {
            notification.error({
              message: `Произошла ошибка при открытии ${internalEquipment[equipmentNumber].name}`,
              placement: 'top',
            });
          } else {
            notification.success({
              message: `Замок ${internalEquipment[equipmentNumber].name} успешно открыт`,
              placement: 'top',
            });
          }
        }
      }
    },
    [notification, form, openWarehouseDoor, warehouse],
  );

  const showDeleteConfirm = useCallback(
    async (warehouseId: string) => {
      confirm({
        title: 'Вы уверены, что хотите удалить данный склад?',
        icon: <ExclamationCircleFilled />,
        okText: 'Да',
        okType: 'danger',
        cancelText: 'Нет',
        onOk: async () => {
          await handleDeleteWarehouse(warehouseId);
        },
      });
    },
    [handleDeleteWarehouse],
  );

  const handleCreateInternalEquipment = useCallback(
    async (equipmentNumber: number) => {
      const formValues: Warehouse = form.getFieldsValue();

      const { internalEquipment } = formValues;

      if (internalEquipment) {
        const equipment = internalEquipment[equipmentNumber];

        const internalEquipmentDto = {
          warehouseId,
          name: equipment.name,
          lockNumber: equipment.lockNumber,
        };

        if (equipment.lockNumber.length === 7) {
          const result = await createInternalEquipment(internalEquipmentDto);

          if ('error' in result) {
            notification.error({
              message: 'Произошла ошибка при добавлении двери склада',
              placement: 'top',
            });
          } else {
            notification.success({
              message: 'Дверь склада успешно добавлена',
              placement: 'top',
            });
          }
        }
      }
    },
    [createInternalEquipment, form, notification, warehouseId],
  );

  const handleCreateWorkingHours = useCallback(async () => {
    const { workingHours }: Warehouse = form.getFieldsValue();

    if (!_.isEmpty(workingHours)) {
      const convertedData: WorkingHours = {
        from: (workingHours.from as Dayjs).format('HH:mm'),
        till: (workingHours.till as Dayjs).format('HH:mm'),
        warehouseId,
      };
      const result = await editWorkingHours(convertedData);
      if ('error' in result) {
        notification.error({
          message: 'Произошла ошибка при установке времени работы склада',
          placement: 'top',
        });
      } else {
        notification.success({
          message: 'Время работы склада успешно установлено',
          placement: 'top',
        });
      }
    }
  }, []);

  const handleDeleteInternalEquipment = useCallback(
    async (equipmentNumber: number) => {
      const formValues: Warehouse = form.getFieldsValue();

      const { internalEquipment } = formValues;

      if (internalEquipment) {
        const result = await deleteInternalEquipment(internalEquipment[equipmentNumber].internalEquipmentId);

        if ('error' in result) {
          notification.error({
            message: 'Произошла ошибка при удалении двери склада',
            placement: 'top',
          });
        } else {
          notification.success({
            message: 'Дверь склада успешно удалена',
            placement: 'top',
          });
        }
      }
    },
    [deleteInternalEquipment, form, notification],
  );

  const checkSavedChanges = (changedValue: CustomAny, values: Warehouse): CustomVoid => {
    setSavedChanges(_.isEqual(values, initialWarehouseValues));
  };

  return (
    <Form
      name="warehouse-form"
      initialValues={initialWarehouseValues}
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 24, style: { padding: 0 } }}
      onValuesChange={checkSavedChanges}
    >
      <div className="relative flex flex-col gap-2">
        <Header
          title={<BackButton path="/warehouses" />}
          border={false}
          isDividerShow={!isSavedChanges}
          dividerText="Есть несохраненные данные!"
        />

        <WarehouseForm
          warehouse={warehouse}
          showDeleteConfirm={showDeleteConfirm}
          handleOpenWarehouseDoor={handleOpenWarehouseDoor}
          isOpenWarehouseDoorLoading={isOpenWarehouseDoorLoading}
          isDeleteWarehouseLoading={isDeleteWarehouseLoading}
          isUpdateWarehouseLoading={isUpdateWarehouseLoading}
          isCreateWarehouseLoading={isCreateWarehouseLoading}
          isSavedChanges={isSavedChanges}
          handleCreateInternalEquipment={handleCreateInternalEquipment}
          handleDeleteInternalEquipment={handleDeleteInternalEquipment}
          handleCreateWorkingHours={handleCreateWorkingHours}
        />
      </div>
    </Form>
  );
};

export default WarehousePage;
