import { apiSlice } from 'api/apiSlice';
import { HistoryDocument } from 'features/interfaces/HistoryDocument';
import { Paginated, PaginationQueryObject } from 'shared/types/generics';

import { HISTORY_BASE_PATH, HISTORY_EXPORT_PATH } from './constants';

interface HistoryQueryObject extends PaginationQueryObject {
  actionName?: string | string[];
  warehouseId?: string | string[];
  boxId?: string;
  userId?: string;
  targetName?: string;
  targetType?: string;
  warehouseName?: string;
  userName?: string;
  boxName?: string;
  startDate?: string;
  endDate?: string;
}

export const historyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query<Paginated<HistoryDocument>, HistoryQueryObject>({
      query: (queryObject) => ({
        url: HISTORY_BASE_PATH,
        params: queryObject,
      }),
      keepUnusedDataFor: 1,
      providesTags: (result) => ['History'],
    }),
    exportHistory: builder.mutation<{ tpye: string; data: Buffer }, HistoryDocument[]>({
      query: (actions) => ({
        url: HISTORY_EXPORT_PATH,
        method: 'POST',
        body: actions,
      }),
    }),
  }),
});

export const { useGetHistoryQuery, useLazyGetHistoryQuery, useExportHistoryMutation } = historyApiSlice;
