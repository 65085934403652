import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { InternalEquipmentDocument } from 'features/interfaces/InternalEquipment';
import { useOpenWarehouseDoorMutation } from 'features/warehouses/warehousesApiSlice';
import { CustomVoid } from 'shared/types/generics';

interface WarehouseLocksProps {
  locks: InternalEquipmentDocument[] | undefined;
  warehouseId: string | undefined;
  className: string;
}

const WarehouseLocks: FC<WarehouseLocksProps> = (props) => {
  const { locks, warehouseId = '', className } = props;

  const [openWarehouseDoor] = useOpenWarehouseDoorMutation();

  const handleOpen = async (internalEquipmentId: string): Promise<CustomVoid> => {
    await openWarehouseDoor({ warehouseId, internalEquipmentId });
  };

  return (
    <div className={className}>
      {locks?.map((iEquipment: InternalEquipmentDocument) => (
        <div key={iEquipment.name} className="space-x-3 flex flex-row items-center p-3">
          <span>{iEquipment.name}</span>
          <Button
            size="small"
            onClick={async () => {
              await handleOpen(iEquipment.internalEquipmentId);
            }}
          >
            Открыть
          </Button>
        </div>
      ))}
    </div>
  );
};

export default WarehouseLocks;
