import { Badge } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TargetType } from 'features/history/enums/HistoryActionName';
import { HistoryDocument } from 'features/interfaces/HistoryDocument';
import { HISTORY_ACTION_TRANSLATES, HISTORY_ACTION_TYPE_TRANSLATES, HISTORY_TYPE_TRANSLATES } from '../../../shared/utils/constants';

const dateOtions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZone: 'Europe/Moscow',
};

const russianDateFormat = new Intl.DateTimeFormat('ru-RU', dateOtions);

const russianTimeFormat = new Intl.DateTimeFormat('ru-RU', timeOptions);

export const HISTORY_TABLE_COLUMNS: ColumnsType<HistoryDocument & Record<'isNewDynamicElement', boolean>> = [
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'date',
    align: 'center',
    width: '170px',
    render: (text, record) => (
      <div className="flex items-center justify-center">
        {record.isNewDynamicElement && <Badge status="success" offset={[-5, 0]} />}
        <p>{russianDateFormat.format(new Date(record.createdAt.replace('Z', '+00:00')))}</p>
      </div>
    ),
  },
  {
    title: 'Время',
    dataIndex: 'createdAt',
    key: 'time',
    align: 'center',
    render: (text, record) => russianTimeFormat.format(new Date(record.createdAt.replace('Z', '+00:00'))),
  },
  {
    title: 'Склад',
    key: 'warehouse',
    align: 'center',
    render: (text, record) => record.actionData.warehouseName || record.actionData.warehouseId,
  },
  {
    title: 'Обьект',
    key: 'targetType',
    width: '15%',
    align: 'center',
    render: (text, record) => {
      switch (record?.actionData.targetType) {
        case TargetType.BOX:
          return `${HISTORY_TYPE_TRANSLATES[record.actionData.targetType]} ${record.actionData.boxName || ''}`;
        case TargetType.USER:
          return `${HISTORY_TYPE_TRANSLATES[record.actionData.targetType]} ${record.actionData.userName || ''}`;
        case TargetType.WAREHOUSE:
          return `${HISTORY_TYPE_TRANSLATES[record.actionData.targetType]}`;

        default:
          return `${
            record?.actionData.targetType ? HISTORY_TYPE_TRANSLATES[record.actionData.targetType] || record.actionData.targetType : ''
          } ${record?.actionData.targetName || ''}`;
      }
    },
  },
  {
    title: 'Событие',
    dataIndex: 'actionName',
    key: 'actionName',
    align: 'center',
    render: (actionName, record) => (
      <div className="flex items-center justify-center">
        {record.isNewDynamicElement && <Badge status="success" offset={[-5, 0]} />}
        <p>{HISTORY_ACTION_TRANSLATES[actionName] || actionName}</p>
      </div>
    ),
  },
  {
    title: 'Пользователь',
    key: 'user',
    align: 'center',
    render: (text, record) => record.actionData.userName,
  },
  {
    title: 'Сообщение',
    key: 'message',
    align: 'center',
    render: (text, record) => {
      let message = 'Событие было совершено ';

      if (record.actionData.boxTimeToAlert) {
        return `Бокс ${HISTORY_ACTION_TYPE_TRANSLATES[record.actionName](record.actionData.boxTimeToAlert)}`;
      }

      if (record?.actionData.userName) {
        message += `пользователем ${record.actionData.userName} `;
      }

      if (record?.actionData.boxName) {
        message += `в боксе ${record.actionData.boxName} `;
      }

      if (record?.actionData.warehouseName) {
        message += `на складе ${record.actionData.warehouseName}`;
      }

      return message;
    },
  },
];
