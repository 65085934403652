import { RouteInterface } from 'features/interfaces/common';
import BoxesList from 'pages/boxes/BoxesList';
import HistoryPage from 'pages/history/HistoryPage';
import WarehouseHistoryPage from 'pages/history/WarehouseHistoryPage';
import Login from 'pages/login/Login';
import UsersPage from 'pages/users/UsersPage';
import WarehouseList from 'pages/warehouses/WarehouseList';
import WarehousePage from 'pages/warehouses/WarehousePage';
import HomePage from 'pages/home/HomePage';

export const generateRoutes = (): RouteInterface[] => [
  {
    isPublic: true,
    path: 'login',
    element: Login,
  },
  {
    isPublic: false,
    path: '/',
    element: HomePage,
    isAdmin: false,
  },
  {
    isPublic: false,
    path: '/users',
    element: UsersPage,
    isAdmin: false,
  },
  {
    isPublic: false,
    path: '/warehouses',
    element: WarehouseList,
    isAdmin: false,
  },
  {
    isPublic: false,
    path: '/warehouses/settings',
    element: WarehousePage,
    isAdmin: true,
  },
  {
    isPublic: false,
    path: '/warehouses/:id/history',
    element: WarehouseHistoryPage,
    isAdmin: true,
  },
  {
    isPublic: false,
    path: '/boxes',
    element: BoxesList,
    isAdmin: true,
  },
];
