import React, { FC, useCallback, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { App } from 'antd';
import { ModalMode } from 'features/enums/modalMode.enum';
import { UserDocument } from 'features/interfaces/UserDocument';
import { useDeleteUserMutation, useGetAllUsersQuery } from 'features/users/usersApiSlice';
import { useGetAllExtendedWarehousesQuery } from 'features/warehouses/warehousesApiSlice';
import { ServerTableActions, ServerTableRowActions } from 'shared/components/table/interfaces';
import ServerTable from 'shared/components/table/ServerTable';
import { getQueryErrorMessage } from 'shared/helpers/queryErrorMessageHelper';

import { CustomVoid, Nullable, RtkQueryPaginatedFunction } from '../../shared/types/generics';

import { USERS_TABLE_COLUMNS } from './constants/table';
import UserFormModal from './UserForm';

const UsersPage: FC = () => {
  const { notification, modal } = App.useApp();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode | null>(null);
  const [selectedUser, setSelectedUser] = useState<Nullable<UserDocument>>(null);

  const { data: warehousesData } = useGetAllExtendedWarehousesQuery('');
  const [deleteUser, { isLoading: isDeleteUserLoading }] = useDeleteUserMutation();

  const handleCloseModal = useCallback((): CustomVoid => {
    setIsModalOpened(false);
    setModalMode(null);
    setSelectedUser(null);
  }, []);

  const handleOpenModal = useCallback((mode: ModalMode, user?: UserDocument) => {
    setIsModalOpened(true);
    setModalMode(mode);
  }, []);

  const handleEditUser = useCallback(
    (selectedUser: UserDocument): void => {
      setSelectedUser(selectedUser);
      handleOpenModal(ModalMode.EDIT);
    },
    [handleOpenModal],
  );

  const handleDeleteUser = useCallback(
    async (user: UserDocument) => {
      modal.confirm({
        title: 'Удаление пользователя',
        content: `Вы уверены, что хотите удалить ${user.username}`,
        onOk: async () => {
          const result = await deleteUser(user.userId);
          if ('error' in result) {
            notification.error({
              message: 'Ошибка',
              description: getQueryErrorMessage(result.error),
              placement: 'bottomRight',
            });
          } else {
            notification.success({
              message: 'Успешно',
              placement: 'bottomRight',
            });
          }
        },
      });
    },
    [deleteUser, modal, notification],
  );

  const tableActions: Array<ServerTableActions<UserDocument>> = useMemo(
    () => [
      {
        name: 'Добавить пользователя',
        props: {
          type: 'primary',
        },
        icon: <PlusOutlined />,
        onClick: () => {
          handleOpenModal(ModalMode.CREATE);
        },
      },
    ],
    [handleOpenModal],
  );

  const rowActions: Array<ServerTableRowActions<UserDocument>> = useMemo(
    () => [
      {
        name: 'Редактировать пользователя',
        icon: <EditOutlined />,
        onClick: (selectedUser) => {
          handleEditUser(selectedUser);
        },
      },
      {
        name: 'Удалить пользователя',
        icon: <DeleteOutlined />,
        props: {
          danger: true,
        },
        onClick: (selectedUser) => {
          void handleDeleteUser(selectedUser);
        },
      },
    ],
    [handleDeleteUser, handleEditUser],
  );

  const COLUMNS = useMemo(() => {
    return [
      ...USERS_TABLE_COLUMNS,
      {
        title: 'Назначенные склады',
        dataIndex: 'assignedWarehouseIds',
        key: 'assignedWarehouseIds',
        width: '30%',
        render: (assignedWarehouseIds: string[]) => (
          <div className="flex justify-center">
            <p>
              {assignedWarehouseIds
                .map((id: string) => {
                  const warehouse = warehousesData?.find((warehouse) => warehouse.warehouseId === id);
                  return warehouse?.name || id;
                })
                .join(', ')}
            </p>
          </div>
        ),
      },
    ];
  }, [warehousesData]);

  return (
    <section className="users-page">
      <ServerTable
        title="Пользователи"
        className="mb-6"
        columns={COLUMNS}
        loading={isDeleteUserLoading}
        rowKey="userId"
        fetchData={useGetAllUsersQuery as RtkQueryPaginatedFunction}
        tableActions={tableActions}
        rowActions={rowActions}
      />
      <UserFormModal
        mode={modalMode}
        isOpen={isModalOpened}
        onCloseModal={handleCloseModal}
        user={selectedUser}
        warehouses={warehousesData}
      />
    </section>
  );
};

export default UsersPage;
