import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import HistoryPage from 'pages/history/HistoryPage';
import Summary from './components/Summary';

const HomePage: React.FC = () => {
  const [activeKey, setActiveKey] = useState('1');

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Журнал событий',
      children: <HistoryPage />,
    },
    {
      key: '2',
      label: 'Сводка',
      children: <Summary setActiveKey={setActiveKey} />,
    },
  ];

  return (
    <Tabs
      activeKey={activeKey}
      items={items}
      onChange={(activeKey) => {
        setActiveKey(activeKey);
      }}
      tabBarStyle={{ paddingLeft: '25px', fontWeight: 'bold', fontSize: '24px' }}
    />
  );
};

export default HomePage;
