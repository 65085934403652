import React from 'react';
import ReactInputMask from 'react-input-mask';
import { CheckOutlined, EditFilled } from '@ant-design/icons';
import { Button, Collapse, Form, Input } from 'antd';
import { useUpdateBoxMutation } from 'features/boxes/boxesApiSlice';
import { UpdateBoxDTO } from 'features/interfaces/Box';
import { CustomVoid } from 'shared/types/generics';
import { castFromMinToMs } from 'shared/utils/functions';

import './BoxLock.scss';

interface EditableCollapseProps {
  value: string;
  boxId: string;
  mask: string;
  fieldToUpdate: string;
  placeholder?: string;
  isNeedCast?: boolean;
}

const EditableCollapse: React.FC<EditableCollapseProps> = (props) => {
  const { value, boxId, mask, fieldToUpdate, placeholder = mask, isNeedCast } = props;

  const [updateBox] = useUpdateBoxMutation();

  const handleUpdateBox = async (updateBoxDto: UpdateBoxDTO): Promise<CustomVoid> => {
    const convertedData = updateBoxDto;
    if (isNeedCast) {
      convertedData.openedTimeToAlert = castFromMinToMs(updateBoxDto.openedTimeToAlert as number);
    }
    await updateBox({ ...convertedData, boxId });
  };

  return (
    <div>
      <Collapse
        size="small"
        bordered={true}
        expandIcon={() => <EditFilled style={{ fontSize: 20, fontWeight: 'bold' }} />}
        expandIconPosition="end"
        items={[
          {
            key: '1',
            label: value || ' - ',
            children: (
              <Form onFinish={handleUpdateBox} className="w-100 flex flex-row space-x-3">
                <Form.Item name={fieldToUpdate} style={{ margin: 0 }} rules={[{ required: true, message: 'Заполните 😡' }]}>
                  <ReactInputMask mask={mask} maskPlaceholder={null}>
                    <Input placeholder={placeholder} className="w-75" size="small" />
                  </ReactInputMask>
                </Form.Item>
                <Button
                  htmlType="submit"
                  type="ghost"
                  className="box-lock-custom-icon"
                  shape="circle"
                  icon={<CheckOutlined style={{ fontSize: 15, color: 'green', fontWeight: 'bold', cursor: 'pointer' }} />}
                />
              </Form>
            ),
          },
        ]}
      />
    </div>
  );
};

export default EditableCollapse;
