import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CustomAny } from 'shared/types/generics';
import { QueryError } from 'store/store';

export const isSerializedError = (err: FetchBaseQueryError | SerializedError): err is SerializedError => {
  return 'message' in err;
};

export const getFetchBaseQueryErrorMessage = (err: FetchBaseQueryError): string => {
  if ('error' in err) {
    return err.error;
  }
  if ('data' in err) {
    return typeof err.data === 'string' ? err.data : (err.data as CustomAny).message || 'Server error';
  }
  return 'Server error';
};

export const getQueryErrorMessage = (error: QueryError): string => {
  return isSerializedError(error) ? error.message || 'Server error' : getFetchBaseQueryErrorMessage(error);
};
