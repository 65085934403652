export const tableStyles: Record<string, React.CSSProperties> = {
  filterTag: {
    height: 35,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: '#289ACA',
    backgroundColor: '#ecf2ff',
    border: 'none',
  },

  filterTagContent: {
    overflow: 'hidden',
    maxWidth: 460,
  },

  filterTagContentItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
