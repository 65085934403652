/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useDebounce } from 'react-use';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Dropdown, Input, MenuProps, Select, Table, Tag, Tooltip } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import classNames from 'classnames';
import Header from 'shared/components/header/Header';
import NoResult from 'shared/components/noResult/NoResult';
import { AnyObject, CustomAny, RtkQueryPaginatedFunction } from 'shared/types/generics';
import { HistoryActionName } from 'features/history/enums/HistoryActionName';

import {
  ServerTableActions,
  ServerTableFilter,
  ServerTableFilterTag,
  ServerTableRowActions,
  ServerTableRowSelectionActions,
} from './interfaces';

import { tableStyles } from './styles/styles';
import './Table.scss';

const { RangePicker } = DatePicker;

interface ServerTableProps {
  columns: ColumnsType<CustomAny>;
  fetchData: RtkQueryPaginatedFunction;
  title?: React.ReactNode;
  bordered?: boolean;
  rowKey: string;
  loading?: boolean;
  search?: boolean;
  className?: string;
  filters?: ServerTableFilter[];
  newElement?: CustomAny;
  tableActions?: Array<ServerTableActions<CustomAny>>;
  rowActions?: Array<ServerTableRowActions<CustomAny>>;
  rowSelectionActions?: Array<ServerTableRowSelectionActions<CustomAny>>;
  defaultFilters?: AnyObject;
  applyQueryFilters?: boolean;
  scroll?: TableProps<CustomAny>['scroll'] & {
    scrollToFirstRowOnChange?: boolean;
  };
}

interface TableParams {
  pagination?: TablePaginationConfig;
}

// нужно вынести наверх
const rowClassName: Record<string, string> = {
  [HistoryActionName.box_unauthorized_access]: 'bg-rose-300',
  [HistoryActionName.box_open_long_time]: 'bg-orange-300',
};

const timeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZone: 'Europe/Moscow',
};

const russianTimeFormat = new Intl.DateTimeFormat('ru-RU', timeOptions);

const ServerTable: FC<ServerTableProps> = (props) => {
  const {
    columns,
    className,
    bordered = true,
    rowKey = '',
    search = false,
    loading = false,
    fetchData,
    filters = [],
    newElement,
    title,
    tableActions = [],
    rowActions = [],
    rowSelectionActions = [],
    defaultFilters,
    applyQueryFilters = false,
    scroll,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const queryFilters = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
  const [dataSource, setDataSource] = useState<AnyObject[]>([]);
  const [dynamicData, setDynamicData] = useState<AnyObject | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const isFiltersEnabled = Boolean(filters.length);
  const [filtersObject, setFiltersObject] = useState<AnyObject>(defaultFilters || {});
  const [debouncedFiltersObject, setDebouncedFiltersObject] = useState<AnyObject>(defaultFilters || {});

  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);

  const blockSearchParamsChange = useRef<boolean>(true);

  useDebounce(
    () => {
      const filtersCopy = { ...filtersObject };
      delete filtersCopy.search;

      blockSearchParamsChange.current = false;

      setDebouncedFiltersObject(
        searchText
          ? {
              ...filtersCopy,
              search: searchText,
            }
          : filtersCopy,
      );

      setTableParams((prevState) => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            current: 1,
          },
        };
      });
    },
    600,
    [filtersObject, searchText],
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} элементов`,
    },
  });

  const FiltersDTO = useMemo(() => {
    const copyFiltersObject = { ...debouncedFiltersObject };

    Object.keys(copyFiltersObject).forEach((key) => {
      if (!copyFiltersObject[key] || _.isEmpty(copyFiltersObject[key])) delete copyFiltersObject[key];
    });

    return copyFiltersObject;
  }, [debouncedFiltersObject]);

  const {
    data: dataList,
    isLoading,
    isError,
  } = fetchData(
    {
      limit: tableParams?.pagination?.pageSize,
      page: tableParams?.pagination?.current,
      ...FiltersDTO,
    },
    {
      skip: !(tableParams?.pagination?.pageSize || tableParams?.pagination?.current),
    },
  );

  // default filters will apply only one time

  /* useEffect(() => {
    if (!_.isEqual(defaultFilters, filtersObject) && defaultFilters) {
      setFiltersObject(defaultFilters);
    }
  }, [defaultFilters]); // [defaultFilters] is correct array dependency */

  useEffect(() => {
    const queryFiltersObject: AnyObject = {};

    for (const key in queryFilters) {
      if (Object.prototype.hasOwnProperty.call(queryFilters, key)) {
        let element: string | string[] = queryFilters[key].split(',');

        if (element.length === 1) {
          element = queryFilters[key];
        }

        queryFiltersObject[key] = element;
      }
    }

    if (!_.isEqual(queryFiltersObject, filtersObject) && queryFiltersObject && applyQueryFilters) {
      blockSearchParamsChange.current = true;

      const multipleSelectFilters = filters.filter((item) => item.type === 'Select' && item?.props?.mode === 'multiple');

      const normilizedFilters = Object.keys(queryFiltersObject).reduce((acc: AnyObject, key: string) => {
        if (multipleSelectFilters.find((filter) => filter.name === key)) {
          const valuesArray = Array.isArray(queryFiltersObject[key]) ? queryFiltersObject[key] : [queryFiltersObject[key]];
          acc[key] = [...valuesArray];
        } else {
          acc[key] = queryFiltersObject[key];
        }
        return acc;
      }, {});

      setFiltersObject(normilizedFilters);
    }
  }, [applyQueryFilters, queryFilters]); // [applyQueryFilters, queryFilters] is correct array dependency */

  useEffect(() => {
    if (!blockSearchParamsChange.current && applyQueryFilters) {
      setSearchParams(new URLSearchParams(debouncedFiltersObject));
    }
  }, [applyQueryFilters, debouncedFiltersObject, setSearchParams]);

  useEffect(() => {
    setDynamicData(newElement ? { ...newElement, isNewDynamicElement: true } : null);
  }, [newElement]);

  useEffect(() => {
    setDataSource(dataList?.content || []);
  }, [dataList]);

  useEffect(() => {
    if (dataList?.total && dataList.total !== tableParams?.pagination?.total) {
      setTableParams((prevState) => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            total: dataList.total,
          },
        };
      });
    }
  }, [dataList, tableParams]);

  useEffect(() => {
    if (dynamicData) {
      if (tableParams?.pagination?.current === 1) {
        setIsShowWarning(false);
        setDataSource((prevData) => [dynamicData, ...[...prevData].splice(0, prevData.length - 1)]);
        setDynamicData(null);
      } else {
        setIsShowWarning(true);
      }
    }
  }, [dynamicData, tableParams?.pagination]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchText(e.target.value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const isShowHeader = useMemo(
    () => isShowWarning || (title && search) || (title && !search && !isFiltersEnabled && !tableActions && !rowSelectionActions),
    [isFiltersEnabled, isShowWarning, rowSelectionActions, search, tableActions, title],
  );

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        ...pagination,
      },
    }));
  };

  const resetFilters = useCallback(
    (filters: ServerTableFilter[] | ServerTableFilter) => {
      const filtersCopy = { ...filtersObject };

      if (!Array.isArray(filters)) {
        filters = [filters];
      }

      filters.forEach(({ name, type }) => {
        if (filtersCopy[name]) {
          delete filtersCopy[name];
        }

        if (type === 'RangePicker') {
          const filterkeys = name.split(',');

          if (filterkeys.length === 2) {
            delete filtersCopy[filterkeys[0]];
            delete filtersCopy[filterkeys[1]];
          } else {
            delete filtersCopy.startDate;
            delete filtersCopy.endDate;
          }
        }
      });

      setFiltersObject(filtersCopy);
    },
    [filtersObject],
  );

  const filterTagsComponent = useMemo(() => {
    const filterTags = filters.reduce((result: ServerTableFilterTag[], filter) => {
      const tag = {
        type: filter.type,
        name: filter.name,
        props: filter.props,
        value: debouncedFiltersObject[filter.name],
      };

      if (debouncedFiltersObject[filter.name]) {
        return [...result, tag];
      }

      if (tag.type === 'RangePicker') {
        const filterkeys = tag.name.split(',');

        if (filterkeys.length === 2) {
          tag.value = [debouncedFiltersObject[filterkeys[0]], debouncedFiltersObject[filterkeys[1]]];
        } else {
          tag.value = [debouncedFiltersObject.startDate, debouncedFiltersObject.endDate];
        }

        if (tag.value.filter((e: string | undefined) => e).length > 0) {
          return [...result, tag];
        }
      }

      return result;
    }, []);

    return filterTags.map((filterTag) => {
      if (filterTag.type === 'Select' && filterTag.props && filterTag.props.options) {
        if (Array.isArray(filterTag.value) && filterTag.value.length > 1) {
          return (
            <Tooltip
              key={filterTag.name}
              title={
                filterTag.value
                  ? filterTag.value.reduce((acc, value) => {
                      return `${acc} ${
                        (
                          filterTag.props?.options.find((e: { value: string; label: string }) => e.value === value) || {
                            label: filterTag.value,
                          }
                        ).label
                      }, `;
                    }, '')
                  : ''
              }
            >
              <Tag
                style={tableStyles.filterTag}
                closable
                onClose={() => {
                  resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
                }}
              >
                <div style={tableStyles.filterTagContent}>
                  <div style={tableStyles.filterTagContentItem}>
                    {`${
                      (
                        filterTag.props?.options.find((e: { value: string; label: string }) => e.value === filterTag.value[0]) || {
                          label: filterTag.value[0],
                        }
                      ).label
                    } + ${filterTag.value.length - 1}`}
                  </div>
                </div>
              </Tag>
            </Tooltip>
          );
        }

        return (
          <Tag
            key={filterTag.name}
            style={tableStyles.filterTag}
            closable
            onClose={() => {
              resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
            }}
          >
            <div style={tableStyles.filterTagContent}>
              <div style={tableStyles.filterTagContentItem}>
                {
                  (
                    filterTag.props?.options.find(
                      (e: { value: string; label: string }) =>
                        e.value === (Array.isArray(filterTag.value) ? filterTag.value[0] : filterTag.value),
                    ) || {
                      label: Array.isArray(filterTag.value) ? filterTag.value[0] : filterTag.value,
                    }
                  ).label
                }
              </div>
            </div>
          </Tag>
        );
      }

      if (filterTag.type === 'RangePicker') {
        return (
          <Tag
            key={filterTag.name}
            style={tableStyles.filterTag}
            closable
            onClose={() => {
              resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
            }}
          >
            <div style={tableStyles.filterTagContent}>
              <div style={tableStyles.filterTagContentItem}>
                от {russianTimeFormat.format(new Date(filterTag.value[0].replace('Z', '+00:00')))} до{' '}
                {russianTimeFormat.format(new Date(filterTag.value[1].replace('Z', '+00:00')))}
              </div>
            </div>
          </Tag>
        );
      }

      return (
        <Tag
          key={filterTag.name}
          style={tableStyles.filterTag}
          closable
          onClose={() => {
            resetFilters(filters.find((e) => e.name === filterTag.name) as ServerTableFilter);
          }}
        >
          <div style={tableStyles.filterTagContent}>
            <div style={tableStyles.filterTagContentItem}>{filterTag.value}</div>
          </div>
        </Tag>
      );
    });
  }, [debouncedFiltersObject, filters, resetFilters]);

  const renderTableActions = useCallback((): ReactNode => {
    let actions = [...tableActions.map((e) => ({ ...e, type: 'table' }))];
    let selectedElements: CustomAny[] = [];

    if (hasSelected) {
      actions = [...actions, ...rowSelectionActions.map((e) => ({ ...e, type: 'row' }))];
      selectedElements = selectedRowKeys.map((id: React.Key) => dataSource.find((item: CustomAny) => item[rowKey] === id));
    }

    if (Object.keys(debouncedFiltersObject).length > 1) {
      actions.unshift({
        name: 'Сбросить фильтры',
        type: 'table',
        onClick: (actions) => {
          setFiltersObject({});
        },
      });
    }

    if (actions.length > 2) {
      const additionalActions: MenuProps['items'] = actions.slice(2).map((action, i) => {
        return {
          label: <p>{action.name}</p>,
          disabled: action.loading,
          itemIcon: action.icon,
          key: i,
          onClick: () => {
            action.onClick(action.type === 'row' ? selectedElements : dataSource);
          },
          ...action.props,
        };
      });

      return (
        <>
          {actions.slice(0, 2).map((action) => {
            return (
              <Button
                key={action.name}
                loading={action.loading}
                className="ml-3"
                {...action.props}
                icon={action.icon}
                size="middle"
                onClick={() => {
                  action.onClick(action.type === 'row' ? selectedElements : dataSource);
                }}
              >
                {action.name}
              </Button>
            );
          })}
          <Dropdown className="ml-3" menu={{ items: additionalActions }} trigger={['click']} placement="bottom">
            <Button icon={<EllipsisOutlined rotate={90} />}>Дополнительно</Button>
          </Dropdown>
        </>
      );
    }

    return (
      <>
        {actions.map((action) => {
          return (
            <Button
              key={action.name}
              loading={action.loading}
              className="ml-3"
              {...action.props}
              icon={action.icon}
              size="middle"
              onClick={() => {
                action.onClick(action.type === 'row' ? selectedElements : dataSource);
              }}
            >
              {action.name}
            </Button>
          );
        })}
      </>
    );
  }, [dataSource, debouncedFiltersObject, hasSelected, rowKey, rowSelectionActions, selectedRowKeys, tableActions]);

  const renderRowActions = useCallback(
    (item: AnyObject) => {
      if (rowActions.length > 2) {
        const additionalActions: MenuProps['items'] = rowActions.slice(2).map((action, i) => {
          return {
            label: <p>{action.name}</p>,
            itemIcon: action.icon,
            key: i,
            onClick: () => {
              action.onClick(item);
            },
            ...action.props,
          };
        });

        return (
          <>
            {rowActions.slice(0, 2).map((action) => {
              return (
                <Tooltip key={action.name} title="prompt text" color="#289ACA">
                  <Button
                    className="ml-3"
                    {...action.props}
                    icon={action.icon}
                    size="middle"
                    onClick={() => {
                      action.onClick(item);
                    }}
                  />
                </Tooltip>
              );
            })}
            <Dropdown className="ml-3" menu={{ items: additionalActions }} trigger={['click']} placement="bottom">
              <Button icon={<EllipsisOutlined rotate={90} />} />
            </Dropdown>
          </>
        );
      }

      return (
        <>
          {rowActions.map((action) => {
            return (
              <Tooltip key={action.name} title={action.name} color="#289ACA">
                <Button
                  className="ml-3"
                  {...action.props}
                  icon={action.icon}
                  size="middle"
                  onClick={() => {
                    action.onClick(item);
                  }}
                />
              </Tooltip>
            );
          })}
        </>
      );
    },
    [rowActions],
  );

  const generateFilterInputField = useCallback(
    (filter: ServerTableFilter) => {
      switch (filter.type) {
        case 'Input':
          // ONLY FOR SKLADOVKA INTERNAL LOGIC
          if (
            !(filtersObject?.targetType?.length && filtersObject.targetType.find((e: string) => e === 'door')) &&
            filter.name === 'targetName'
          ) {
            return null;
          }
          return (
            <div key={filter.name} className="table-filter-item">
              <Input
                onChange={(e) => {
                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: e.target.value,
                  }));
                }}
                allowClear
                value={filtersObject[filter.name]}
                {...filter.props}
                style={filter.style}
              />
            </div>
          );

        case 'Checkbox':
          return (
            <div key={filter.name} className="table-filter-item">
              <Checkbox
                onChange={(e) => {
                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: e.target.checked,
                  }));
                }}
                checked={filtersObject[filter.name]}
                {...filter.props}
                style={filter.style}
              >
                {filter.props?.label || filter.name}
              </Checkbox>
            </div>
          );

        case 'Select':
          return (
            <div key={filter.name} className="table-filter-item">
              <Select
                onChange={(value) => {
                  if (value === undefined || value?.length === 0) {
                    resetFilters(filters.find((e) => e.name === filter.name) as ServerTableFilter);
                  } else {
                    setFiltersObject((prev) => ({
                      ...prev,
                      [filter.name]: value,
                    }));
                  }
                }}
                value={filtersObject[filter.name]}
                {...filter.props}
                style={filter.style}
              />
            </div>
          );

        case 'DatePicker':
          return (
            <div key={filter.name} className="table-filter-item">
              <DatePicker
                onChange={(date) => {
                  let isoDate = '';

                  if (date) {
                    isoDate = filter.props?.showTime ? date.toISOString() : `${date.toISOString().split('T')[0]}T00:00:00.000Z`;
                  }

                  setFiltersObject((prev) => ({
                    ...prev,
                    [filter.name]: isoDate,
                  }));
                }}
                {...filter.props}
                value={filtersObject[filter.name] ? dayjs(filtersObject[filter.name]) : undefined}
                style={{
                  width: 265,
                  ...filter.style,
                }}
              />
            </div>
          );

        case 'RangePicker':
          return (
            <div key={filter.name} className="table-filter-item table-filter-item-rangepicker">
              <RangePicker
                suffixIcon={null}
                onChange={(date) => {
                  let isoDateArray: string[] | null = null;

                  if (date?.[0] && date[1]) {
                    isoDateArray = filter.props?.showTime
                      ? [date[0]?.toISOString(), date[1]?.toISOString()]
                      : [date[0]?.toISOString().split('T')[0], date[1]?.toISOString().split('T')[0]];
                  }

                  const filterkeys = filter.name.split(',');
                  const datesobject: AnyObject = {};

                  if (filterkeys.length === 2) {
                    datesobject[filterkeys[0]] = isoDateArray ? isoDateArray[0] : '';
                    datesobject[filterkeys[1]] = isoDateArray ? isoDateArray[1] : '';
                  } else {
                    datesobject.startDate = isoDateArray ? isoDateArray[0] : '';
                    datesobject.endDate = isoDateArray ? isoDateArray[0] : '';
                  }

                  setFiltersObject((prev) => ({
                    ...prev,
                    ...datesobject,
                  }));
                }}
                value={
                  filtersObject[filter.name.split(',')[1]] || filtersObject.endDate
                    ? [
                        dayjs(filtersObject[filter.name.split(',')[0]] || filtersObject.startDate || undefined),
                        dayjs(filtersObject[filter.name.split(',')[1]] || filtersObject.endDate || undefined),
                      ]
                    : undefined
                }
                presets={[
                  {
                    label: 'Сегодня',
                    value: [dayjs().startOf('day'), dayjs().endOf('day')],
                  },
                  {
                    label: 'За последние 7 дней',
                    value: [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')],
                  },
                  {
                    label: 'За текущий месяц',
                    value: [dayjs().startOf('month').startOf('day'), dayjs().endOf('day')],
                  },
                ]}
                {...filter.props}
                style={{
                  width: 265,
                  ...filter.style,
                }}
              />
            </div>
          );

        default:
          return null;
      }
    },
    [filters, filtersObject, resetFilters],
  );

  const getColumnFilterComponent = useCallback(
    (columnName: string) => {
      const columnFilters = filters.filter((item) => item.column === columnName);

      if (columnFilters.length === 0) {
        return { filterDropdown: null };
      }

      const filtersList = columnFilters.map(generateFilterInputField);

      return {
        filterDropdown: ({ close, visible }: FilterDropdownProps) => {
          return (
            <div id={`filter-dropdown-${columnName}`} style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
              <div className="flex justify-between items-center mb-3">
                <h2>Фильтры</h2>
                <Button
                  type="link"
                  onClick={() => {
                    close();
                  }}
                >
                  Закрыть
                </Button>
              </div>
              {filtersList}
              <div className="flex justify-end items-center">
                <Button
                  onClick={() => {
                    resetFilters(columnFilters);
                  }}
                >
                  Очистить
                </Button>
              </div>
            </div>
          );
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (!document.getElementById(`filter-dropdown-${columnName}`)) {
            setTimeout(() => {
              const dropdown = document.getElementById(`filter-dropdown-${columnName}`);

              dropdown?.parentElement?.classList.add('table-filter-dropdown');
            }, 0);
          }
        },
      };
    },
    [filters, generateFilterInputField, resetFilters],
  );

  const tableColumns = useMemo(() => {
    const resultColumns: ColumnsType<CustomAny> = [
      ...columns.map((column) => {
        return {
          ...column,
          ...getColumnFilterComponent(column.key as string),
        };
      }),
    ];

    if (rowActions.length) {
      resultColumns.push({
        title: '',
        key: 'actions',
        render: (_: null, item: AnyObject) => renderRowActions(item),
      });
    }

    return resultColumns;
  }, [columns, getColumnFilterComponent, renderRowActions, rowActions]);

  const onDividerClick = (): void => {
    setTableParams((prevState) => {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          current: 1,
        },
      };
    });
  };

  return (
    <>
      {isShowHeader && (
        <Header
          isDividerShow={isShowWarning}
          onDividerClick={onDividerClick}
          dividerText="есть новые события"
          title={title}
          border={false}
        />
      )}
      <div className={classNames('table-component w-full sm:px-6 lg:px-8', className)}>
        {(isFiltersEnabled || search || tableActions || rowSelectionActions) && (
          <div className={classNames(isShowHeader ? '' : 'mt-5', 'flex flex-1 items-center mb-5')}>
            {search ? (
              <Input
                className="table-search-input mr-auto"
                placeholder="Search"
                size="large"
                prefix={<SearchOutlined className="search-icon" />}
                onChange={onSearch}
                allowClear
              />
            ) : (
              <div className="mr-auto flex-shrink-0 text-2xl">{title}</div>
            )}
            <div className="overflow-x-auto flex mx-3">{filterTagsComponent}</div>
            {renderTableActions()}
          </div>
        )}
        {!isError ? (
          <Table
            className={classNames(bordered ? 'border-solid border border-gray-200 rounded-lg' : '')}
            columns={tableColumns}
            dataSource={dataSource}
            rowKey={(record) => record[rowKey]}
            onChange={handleTableChange}
            pagination={dataList?.content?.length ? tableParams.pagination : false}
            loading={loading || isLoading}
            rowSelection={rowSelectionActions.length ? rowSelection : undefined}
            rowClassName={(record) => rowClassName[record.actionName]}
            scroll={scroll}
          />
        ) : (
          <NoResult />
        )}
      </div>
    </>
  );
};

export default ServerTable;
