import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { logout, setCredentials } from 'features/auth/authSlice';
import { AUTH_REFRESH_PATH } from 'features/auth/constants';
import { setAuthToken, setRefreshToken } from 'shared/helpers/accessTokenHelper';
import { AnyObject, CustomAny } from 'shared/types/generics';

const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:3001/api';

const baseQuery = fetchBaseQuery({
  baseUrl: apiHost,
  mode: 'cors',
  prepareHeaders: (headers, { getState }: AnyObject) => {
    const token: string = getState().auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const refreshQuery = fetchBaseQuery({
  baseUrl: apiHost,
  mode: 'cors',
  prepareHeaders: (headers, { getState }: AnyObject) => {
    const refresh: string = getState().auth.refresh;

    if (refresh) {
      headers.set('authorization', `Bearer ${refresh}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (
  args: CustomAny,
  api: CustomAny,
  extraOptions: CustomAny,
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const refreshResult = await refreshQuery(AUTH_REFRESH_PATH, api, extraOptions);
    if (refreshResult?.data) {
      const { access_token: token, refresh_token: refresh } = refreshResult.data as Record<'refresh_token' | 'access_token', string>;
      const username = api.getState().auth.username;
      const email = api.getState().auth.email;

      setAuthToken(token, true);
      setRefreshToken(refresh, true);

      api.dispatch(setCredentials({ token, username, refresh, email }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Warehouses', 'Users', 'History', 'Boxes', 'Summary'],
  endpoints: (builder) => ({}),
});
