import { apiSlice } from 'api/apiSlice';
import { BoxDocument, BoxQuery, UpdateBoxDTO } from 'features/interfaces/Box';
import { CustomAny } from 'shared/types/generics';

import { BOXES_BASE_PATH, generateOpenPath, generateStateVerificationPath } from './constants';

export const boxesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBoxes: builder.query<BoxDocument[], BoxQuery>({
      query: (boxQuery: BoxQuery) => ({
        url: BOXES_BASE_PATH,
        params: boxQuery,
      }),
      keepUnusedDataFor: 10,
      providesTags: (result) => ['Boxes'],
    }),
    updateBox: builder.mutation<BoxDocument, UpdateBoxDTO>({
      query: ({ boxId, ...updateBoxDto }) => ({
        url: `${BOXES_BASE_PATH}/${String(boxId)}`,
        method: 'PUT',
        body: updateBoxDto,
      }),
      invalidatesTags: ['Boxes'],
    }),
    verifyState: builder.mutation<BoxDocument, { boxId: string; isStateVerified: boolean }>({
      query: ({ isStateVerified, boxId }) => ({
        url: generateStateVerificationPath(boxId),
        method: 'PUT',
        params: {
          isVerified: isStateVerified,
        },
      }),
      invalidatesTags: ['Boxes', 'Summary'],
    }),
    openBox: builder.mutation<CustomAny, { boxId: string; userId: string }>({
      query: ({ boxId, userId }) => ({
        url: generateOpenPath(boxId),
        method: 'PUT',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['Boxes'],
    }),
  }),
});

export const { useGetAllBoxesQuery, useLazyGetAllBoxesQuery, useUpdateBoxMutation, useOpenBoxMutation, useVerifyStateMutation } =
  boxesApiSlice;
