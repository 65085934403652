import { FC } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Result } from 'antd';

const NoResult: FC = () => (
  <div className="p-[10%]">
    <Result icon={<ExclamationCircleOutlined style={{ color: '#289ACA' }} />} title="No results found" />
  </div>
);

export default NoResult;
