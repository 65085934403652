import { FC, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useSound from 'use-sound';
import _ from 'lodash';
import { Route, Routes, useLocation } from 'react-router-dom';
import { StyleProvider } from '@ant-design/cssinjs';
import { App, ConfigProvider, notification } from 'antd';
import dayjs from 'dayjs';
import ruRu from 'antd/locale/ru_RU';

import RequireAuth from 'features/auth/requireAuth';
import { RouteInterface } from 'features/interfaces/common';
import NotFoundPage from 'pages/notFound/NotFoundPage';
import Authorization from 'shared/components/authorization/Authorization';
import Layout from 'shared/components/Layout';
import { APP_THEME } from 'shared/constants/application';
import { generateRoutes } from 'shared/routes';
import { ROLES_MAP } from 'shared/utils/constants';
import { BoxAlert } from 'features/interfaces/Box';
import { useLocationQuery } from 'shared/utils/hooks';
import { useLazyGetAllBoxesQuery } from 'features/boxes/boxesApiSlice';
import { getInfoFromToken } from 'shared/helpers/accessTokenHelper';
import { WebSocketProvider } from 'shared/utils/websocketcontext';
import openedBoxRingtone from 'ringtones/oh.mp3';
import alarmRingtone from 'ringtones/alarm.mp3';
import { useLazyGetWarehousesSummaryQuery } from 'features/warehouses/warehousesApiSlice';
import { CustomVoid } from 'shared/types/generics';
import 'dayjs/locale/ru';

dayjs.locale('ru');

const Admin = Authorization(ROLES_MAP.superUser);

const MyApp: FC = () => {
  const routes = generateRoutes();
  const location = useLocation();
  const query = useLocationQuery();
  const [triggerGetAllBoxesQuery] = useLazyGetAllBoxesQuery();
  const [triggerSummaryUpdate] = useLazyGetWarehousesSummaryQuery();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [playOpenedBoxRingtone] = useSound(openedBoxRingtone);
  const [playAlarmRingtone] = useSound(alarmRingtone);

  const publicRoutes = routes.filter((route: RouteInterface) => route.isPublic);
  const protectedRoutes = routes.filter((route: RouteInterface) => !route.isPublic);
  const adminRoutes = protectedRoutes.filter((route: RouteInterface) => route.isAdmin);

  const triggerUpdateBoxesAndSummary = async (): Promise<CustomVoid> => {
    if (location.pathname === '/boxes' && query.get('warehouseId')) {
      const boxQuery = {
        warehouseId: query.get('warehouseId') as string,
      };

      await triggerGetAllBoxesQuery(boxQuery);
    }

    await triggerSummaryUpdate();
  };

  useEffect(() => {
    const userInfo = getInfoFromToken();

    if (!_.isNull(userInfo)) {
      const apiHost = process.env.REACT_APP_API_SOCKET_HOST || process.env.REACT_APP_API_HOST || 'http://localhost:3001';

      const socket = io(apiHost, {
        path: '/socket',
        autoConnect: true,
        reconnection: true,
        query: userInfo,
      });

      setSocket(socket);

      return () => {
        socket.disconnect();
      };
    }
  }, [setSocket]);

  useEffect(() => {
    if (!_.isNull(socket)) {
      socket.on('boxAlert', (boxAlert: BoxAlert) => {
        void triggerUpdateBoxesAndSummary();
        notification.error({
          message: boxAlert.message,
          description:
            location.pathname === '/boxes' && query.get('warehouseId') ? (
              ''
            ) : (
              <span>
                Перейдите на страницу{' '}
                <a className="underline text-blue-500" href={`/boxes?warehouseId=${boxAlert.warehouseId}`}>
                  боксов
                </a>
              </span>
            ),
          placement: 'top',
          duration: 0,
        });

        playOpenedBoxRingtone();
      });

      socket.on('unauthorizedAccess', (boxAlert: BoxAlert) => {
        void triggerUpdateBoxesAndSummary();

        notification.error({
          message: boxAlert.message,
          description: <span>Проверьте бокс {boxAlert.boxNumber}</span>,
          placement: 'top',
          duration: 0,
        });

        playAlarmRingtone();
      });

      socket.on('lockStatusChanged', triggerUpdateBoxesAndSummary);

      return () => {
        socket.off('boxAlert');
        socket.off('unauthorizedAccess');
        socket.off('lockStatusChanged');
      };
    }
  }, [socket, playOpenedBoxRingtone, playAlarmRingtone]);

  return (
    <ConfigProvider theme={APP_THEME} locale={ruRu}>
      <StyleProvider hashPriority="high">
        <App>
          <WebSocketProvider value={socket}>
            <Routes>
              <Route path="/" element={<Layout />}>
                {publicRoutes.map((route: RouteInterface) => (
                  <Route path={route.path} element={<route.element />} key={route.path} />
                ))}
                {/* public routes */}
                {/* protected routes */}
                <Route element={<RequireAuth />}>
                  {protectedRoutes.map((route: RouteInterface) => (
                    <Route path={route.path} element={<route.element />} key={route.path} />
                  ))}

                  {adminRoutes.map((route: RouteInterface) => (
                    <Route path={route.path} element={Admin(<route.element />)} key={route.path} />
                  ))}
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </WebSocketProvider>
        </App>
      </StyleProvider>
    </ConfigProvider>
  );
};

export default MyApp;
