import React, { useEffect } from 'react';
import { App, Form, Input, Modal, Select } from 'antd';
import { ModalMode } from 'features/enums/modalMode.enum';
import { Role } from 'features/enums/role.enum';
import { User } from 'features/interfaces/User';
import { UserDocument } from 'features/interfaces/UserDocument';
import { Warehouse } from 'features/interfaces/Warehouse';
import { useAddUserMutation, useUpdateUserMutation } from 'features/users/usersApiSlice';
import { CustomAny, CustomVoid, Nullable } from 'shared/types/generics';

interface UserFormModalProps {
  user: Nullable<UserDocument>;
  warehouses: Nullable<Warehouse[]>;
  mode: ModalMode | null;
  onCloseModal: () => CustomVoid;
  isOpen: boolean;
}

const UserFormModal: React.FC<UserFormModalProps> = (props) => {
  const { mode, isOpen, onCloseModal, user, warehouses } = props;

  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [addUser] = useAddUserMutation();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const onSubmit = async (data: User): Promise<CustomVoid> => {
    try {
      if (mode === ModalMode.CREATE) {
        await addUser(data).unwrap();
        notification.success({ message: 'Успешно', placement: 'topRight' });
      } else if (mode === ModalMode.EDIT) {
        const { userId } = user as UserDocument;
        await updateUser({ ...data, userId }).unwrap();
        notification.success({ message: 'Успешно', placement: 'topRight' });
      }
    } catch (err: CustomAny) {
      notification.error({ message: err.data.message, placement: 'topRight' });
    }

    onCloseModal();
  };

  const handleSelectAll = (value: CustomAny): void => {
    if (value.includes('all')) {
      form.setFieldValue(
        'assignedWarehouseIds',
        (warehouses as Warehouse[]).map((warehouse) => warehouse.warehouseId),
      );
    }
  };

  useEffect(() => {
    if (mode === ModalMode.EDIT) {
      form.setFieldsValue({ ...user, password: undefined });
    } else {
      form.resetFields();
    }
  }, [mode, form, props, user]);

  return (
    <Modal
      open={isOpen}
      title={`${mode === ModalMode.EDIT ? 'Редактирование' : 'Добавление'} пользователя`}
      okText="Сохранить"
      cancelText="Отменить"
      onCancel={onCloseModal}
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit} disabled={isLoading}>
        <Form.Item
          label="Имя пользователя"
          name="username"
          rules={[{ required: mode === ModalMode.CREATE, message: 'Пожалуйста введите имя!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: mode === ModalMode.CREATE, message: 'Пожалуйста введите почту!' },
            { type: 'email', message: 'Введите корректную почту!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: mode === ModalMode.CREATE, message: 'Пожалуйста введите пароль!' }, { min: 6 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Роль пользователя"
          name="role"
          rules={[{ required: mode === ModalMode.CREATE, message: 'Пожалуйста выберите роль пользователя!' }]}
        >
          <Select placeholder="Выберите роль пользователя">
            <Select.Option value={Role.SUPERUSER}>Супер юзер</Select.Option>
            <Select.Option value={Role.MANAGER}>Менеджер</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="assignedWarehouseIds" label="Склады пользователя">
          <Select mode="multiple" allowClear placeholder="Cклады, к которым у пользователя должен быть доступ" onChange={handleSelectAll}>
            <Select.Option key="all" value="all" onSelect>
              Выбрать все склады
            </Select.Option>
            {warehouses?.map((warehouse) => {
              return (
                <Select.Option key={warehouse.warehouseId} value={warehouse.warehouseId}>
                  {warehouse.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
