import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { App, Button } from 'antd';

import { useGetSirenStatusMutation, useTurnAlertOnMutation } from 'features/warehouses/warehousesApiSlice';
import { WebSocketContext } from 'shared/utils/websocketcontext';
import { CustomAny, CustomVoid } from 'shared/types/generics';

import './WarehouseSiren.scss';

interface WarehouseSirenProps {
  warehouseId: string;
}

const WarehouseSiren: React.FC<WarehouseSirenProps> = (props: WarehouseSirenProps) => {
  const { warehouseId } = props;

  const { notification, modal } = App.useApp();

  const socket = useContext(WebSocketContext);

  const [fetchSirenStatus] = useGetSirenStatusMutation();
  const [turnAlertOn] = useTurnAlertOnMutation();

  const [isSirenActive, setIsSirenActive] = useState<boolean>(false);

  const getSirenStatus = async (): Promise<void> => {
    const result = await fetchSirenStatus(warehouseId);

    if (!('error' in result)) {
      setIsSirenActive(result.data.isOn);
    }
  };

  const handleToggleAlert = async (e: CustomAny): Promise<CustomVoid> => {
    e.stopPropagation();

    modal.confirm({
      title: 'Включение сирены',
      content: `Вы уверены, что хотите ${isSirenActive ? 'выключить' : 'включить'} сирену?`,
      onOk: async () => {
        const result = await turnAlertOn(warehouseId);
        if ('error' in result) {
          notification.error({
            message: `Возникла ошибка при ${isSirenActive ? 'выключении' : 'включении'} сирены`,
            placement: 'top',
          });
        } else {
          notification.success({
            message: `Сирена успешно ${isSirenActive ? 'выключена' : 'включена'}`,
            placement: 'top',
          });
        }
      },
    });
  };

  useEffect(() => {
    void getSirenStatus();
  }, [warehouseId]);

  useEffect(() => {
    if (socket) {
      socket.on('siren-change-status', (data) => {
        if (data.warehouseIdSM === warehouseId) {
          setIsSirenActive(data.isSirenOn);
        }
      });

      return () => {
        socket.off('siren-change-status');
      };
    }
  }, [socket]);

  return (
    <Button onClick={handleToggleAlert} className={`${isSirenActive ? 'siren-button-active' : ''}`} danger={isSirenActive}>
      Сирена
    </Button>
  );
};

export default WarehouseSiren;
