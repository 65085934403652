import { LockState } from 'features/enums/lockState.enum';
import { CustomAny } from 'shared/types/generics';
import { addMs, isDateAfterNow } from 'shared/utils/functions';

export const highlightRowBoxesList = (record: CustomAny): string =>
  record.isStateVerified
    ? record.lockStatus === LockState.OPENED &&
      record.lastOpeningTime &&
      isDateAfterNow(addMs(record.lastOpeningTime, record.openedTimeToAlert))
      ? 'bg-orange-100'
      : ''
    : 'bg-rose-100';
