import React, { FC, ReactNode } from 'react';
import { Divider } from 'antd';
import classNames from 'classnames';
import { AnyFunction } from 'shared/types/generics';

import { headerStyles } from './styles';

import './Header.scss';

interface HeaderProps {
  title: ReactNode;
  dark?: boolean;
  border?: boolean;
  children?: ReactNode;
  className?: string;
  isDividerShow?: boolean;
  dividerText?: string;
  onDividerClick?: AnyFunction;
}

const Header: FC<HeaderProps> = (props) => {
  const { className, title, dark = false, border = true, dividerText, isDividerShow = false, onDividerClick } = props;

  return (
    <div
      className={classNames(
        'mx-auto w-full',
        dark ? 'header-dark__color text-white' : 'bg-white text-gray-800',
        border ? 'border-solid border-b border-gray-200' : '',
        className,
      )}
    >
      <div className="relative flex items-center h-16 justify-between" style={isDividerShow ? headerStyles.dividerMargin : {}}>
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start sm:px-6 lg:px-8">
          <div className="flex items-center flex-shrink-0 text-2xl z-index-10">{title}</div>
          <div className="w-full flex justify-end items-center space-x-4 z-index-5">{props.children}</div>
        </div>
        {isDividerShow && (
          <Divider className={'absolute top-[75%] w-screen'} style={headerStyles.headerDivider}>
            <div className="cursor-pointer" onClick={onDividerClick}>
              {dividerText}
            </div>
          </Divider>
        )}
      </div>
    </div>
  );
};

export default Header;
